import { Grid } from '@mui/material'
import React from 'react'
import { getDateInFormat } from '../../Helpers/Util'
import { ChipButton } from '../Shared'
import CONSTANTS from '../../Constants'

const DetailPageRowChip = ({
  name = '',
  value = '',
  isDate = false,
  isStatus = false,
  truncate = true,
}: any) => {
  let displayValue: string

  if (!isDate) {
    if (truncate && value.length > 40) {
      displayValue = `${value.substring(0, 40)}...`
    } else {
      displayValue = value
    }
  } else {
    displayValue = getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS)
  }
  return (
    <Grid container>
      <Grid item xs={6} md={3}>
        <div className='sub-heading-6 view__role-list  text-left col34'>{name}</div>
      </Grid>
      <Grid item xs={6} md={9}>
        {!isStatus ? (
          <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
            {displayValue}
          </div>
        ) : (
          <>
            {value.toUpperCase() === 'ACTIVE' && (
              <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                <ChipButton type='success' label='Active' style={{ padding: '0px 8px' }} />
              </div>
            )}
            {value.toUpperCase() === 'INACTIVE' && (
              <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                <ChipButton type='secondary' label='In Active' style={{ padding: '0px 8px' }} />
              </div>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}
export default React.memo(DetailPageRowChip)
