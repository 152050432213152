import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import * as React from 'react'
import { RoleConfirmationModal } from '../../../Models'
import MUIDialog from '../../Shared/MUI-Dialog'

import { ModalIcon, MUIButton } from '../../Shared'

const RoleConfirmation: React.FC<RoleConfirmationModal> = ({
  open,
  handleClose = () => {
    return
  },
  heading,
  description,
  color = 'primary',
  Icon,
  btnPositionSwap,
  successButtonLabel = 'Submit',
  cancelButtonLabel = 'Cancel',
  variant = 'contained',
  handleSuccess = () => {
    return
  },
}) => {
  return (
    <>
      <div>
        <MUIDialog open={open} handleClose={handleClose}>
          <div className='c-modal' style={{ maxWidth: '400px' }}>
            <DialogTitle>
              <ModalIcon IconName={Icon} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText component={'div'} id='alert-dialog-slide-description'>
                <div className='col2 parag pt-1raph-bold-3 '>{heading}</div>
                <div className='col34 paragraph-regular-5'>
                  {description}
                  <span className='3E3F40 paragraph-bold-5 '></span>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div
                className={`d-flex flex-wrap w-100 p-0 dialog-btns${
                  cancelButtonLabel && successButtonLabel ? '' : ' d-block'
                }`}
              >
                {btnPositionSwap ? (
                  <>
                    {successButtonLabel && (
                      <div
                        className={`accept-action-btn ${
                          !cancelButtonLabel && successButtonLabel && 'w-100'
                        }`}
                      >
                        <MUIButton
                          textTransform={'capitalize'}
                          // variant='contained'
                          variant={variant}
                          size='large'
                          color={color}
                          type='button'
                          handleClickCB={() => handleSuccess()}
                          label={successButtonLabel}
                          width={'100%'}
                        />
                      </div>
                    )}
                    {cancelButtonLabel && (
                      <div className='cancel-action-btn'>
                        <MUIButton
                          textTransform={'capitalize'}
                          variant='outlined'
                          size='large'
                          color={'secondary'}
                          type='button'
                          label={cancelButtonLabel}
                          width={'100%'}
                          handleClickCB={() => handleClose()}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {' '}
                    {cancelButtonLabel && (
                      <div className='cancel-action-btn'>
                        <MUIButton
                          textTransform={'capitalize'}
                          variant='outlined'
                          size='large'
                          color={'secondary'}
                          type='button'
                          label={cancelButtonLabel}
                          width={'100%'}
                          handleClickCB={() => handleClose()}
                        />
                      </div>
                    )}
                    {successButtonLabel && (
                      <div
                        className={`accept-action-btn ${
                          !cancelButtonLabel && successButtonLabel && 'w-100'
                        }`}
                      >
                        <MUIButton
                          textTransform={'capitalize'}
                          // variant='contained'
                          variant={variant}
                          size='large'
                          color={color}
                          type='button'
                          handleClickCB={() => handleSuccess()}
                          label={successButtonLabel}
                          width={'100%'}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </DialogActions>
          </div>
        </MUIDialog>
      </div>
    </>
  )
}

export default RoleConfirmation
