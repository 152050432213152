import React, { useEffect, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  List,
  Heading,
  HeadingButtonsUI,
  ParagraphButtonUI,
  HeadingConfig,
} from 'ckeditor5'
import { TextEditorModel } from '../../../Models'

import 'ckeditor5/ckeditor5.css'

const TextEditor: React.FC<TextEditorModel> = ({
  defaultValue = '',
  handleTextEditorData,
  showHeadings,
  placeholder,
}) => {
  const [editorConfig, setEditorConfig] = React.useState<{
    toolbar: string[]
    heading: HeadingConfig
    plugins: any[]
    placeholder: string
  }>({
    toolbar: ['bold', 'italic', 'bulletedList', 'numberedList'],
    heading: { options: [] },
    plugins: [Bold, Essentials, Italic, Mention, Paragraph, List],
    placeholder: '',
  })
  const [mountEditor, setMountEditor] = useState<boolean>(false)

  useEffect(() => {
    if (showHeadings || placeholder) {
      const oldEditorConfig = { ...editorConfig }
      if (showHeadings) {
        oldEditorConfig.toolbar = ['heading', '|', ...oldEditorConfig.toolbar]
        oldEditorConfig.plugins = [
          Heading,
          HeadingButtonsUI,
          ParagraphButtonUI,
          ...oldEditorConfig.plugins,
        ]
        oldEditorConfig.heading = {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
          ],
        }
      }

      if (placeholder) oldEditorConfig.placeholder = placeholder

      setEditorConfig(oldEditorConfig)
    }
    setMountEditor(true)
  }, [showHeadings, placeholder])

  if (!mountEditor) return null
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: editorConfig.toolbar,
        heading: editorConfig.heading,
        plugins: editorConfig.plugins,
        placeholder: editorConfig.placeholder,
        licenseKey: '<YOUR_LICENSE_KEY>',
      }}
      data={defaultValue} // Set initial data here directly
      onReady={(editor) => {
        // Set initial content on the editor when it's ready
        editor.setData(defaultValue)

        const toolbar = editor.ui.view.toolbar

        const boldButton = toolbar.items.find((item: any) => item?.label === 'Bold')
        const italicButton = toolbar.items.find((item: any) => item?.label === 'Italic')
        const bulletPointButton = toolbar.items.find((item: any) => item?.label === 'Bulleted List')
        const numberPointerButton = toolbar.items.find(
          (item: any) => item?.label === 'Numbered List',
        )

        if (boldButton?.element) {
          boldButton.element.setAttribute('data-testid', 'toolbar-bold-button')
        }

        if (italicButton?.element) {
          italicButton.element.setAttribute('data-testid', 'toolbar-italic-button')
        }

        if (bulletPointButton?.element) {
          bulletPointButton.element.setAttribute('data-testid', 'toolbar-bullet-button')
        }

        if (numberPointerButton?.element) {
          numberPointerButton.element.setAttribute('data-testid', 'toolbar-numberPointer-button')
        }
      }}
      onChange={handleTextEditorData}
    />
  )
}

export default TextEditor
