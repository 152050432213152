import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React from 'react'
import { CONSTANTS } from '../../../Constants/Constant'
import style from '../../Molecule/Molecule.module.scss'

const { MARK_DONE_LABEL, MARK_DONE_STATUS } = CONSTANTS
interface MarkAsDoneModal {
  isViewMode?: boolean | undefined
  disabled: boolean | undefined
  onClickMarkAsDone?: () => void
  status?: keyof typeof MARK_DONE_STATUS | undefined
}

export const MarkAsDone: React.FC<MarkAsDoneModal> = ({
  isViewMode = false,
  disabled = true,
  onClickMarkAsDone,
  status,
}) => {
  const handleClickMarkAsDone = () => {
    if (onClickMarkAsDone) {
      onClickMarkAsDone()
    }
  }

  return (
    <div
      className={!(disabled || isViewMode) ? 'cursor-pointer' : ''}
      role='button'
      aria-disabled={disabled}
      onClick={() => (disabled ? '' : handleClickMarkAsDone())}
      data-testid={disabled ? 'disabledMarkAsDone' : 'enabledMarkAsDone'}
    >
      <div
        style={{ textAlign: 'center' }}
        className={`${disabled ? style.markDone : style.markDoneActive} `}
      >
        <span className='d-flex justify-content-end align-items-center h-26px'>
          <CheckCircleIcon
            style={{
              fill: disabled ? '#b1b4b6' : status === MARK_DONE_STATUS.PASS ? '#005a30' : '#B0CCBF',
            }}
            data-testid='unchecked-icon'
          />
          &nbsp;
          {MARK_DONE_LABEL}
        </span>
      </div>
    </div>
  )
}
