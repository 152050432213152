import * as yup from 'yup'

export const VALIDATIONS = {
  REQUIRED: 'This field is required.',
  EMAIL: 'Please enter a valid email',
  EMAIL_EXIST: 'Email Id already exist',
  WITHOUT_SPACE_ALPHANUMERIC: 'only aphanumeric and "_" (underscore) allowed ,space not allowed',
  CYBERARKID_EXIST: 'CyberArkId Already exists',
  EMAILID_EXIST: 'Email id already exists in our system',
  ONLY_ALPHABET: 'Only alphabets are allowed',
  ONLY_ALPHANUMERIC: 'Only alpha-numeric are allowed',
  MIN_NEGATIVE_FIELD: 'Only positive values are allowed',
  VALID_URL: 'Please enter a valid URL',
  SelectedDataTpeMismatch: 'Data type mis match with current value',

  PARAMETERNAME_ALREADY_EXIST: 'Entered Parameter name already exists!',
  FIELDNAME_ALREADY_EXIST: 'Entered Field name already exists!',
  FIELDNAME_INVALID_VALUE: 'fieldName is having invalid value.',
  PLACEHOLDER_INVALID_VALUE: 'placeholder is having invalid value.',
  MASTER_INVALID_VALUE: 'master is having invalid value.',
  PURPOSE_INVALID_VALUE: 'purpose is having invalid value.',
}
export const RegexPatterns = <any>{
  onlyAlphabate: /^[A-Za-z\s]+$/i,
  alphaNumeric: /^[A-Za-z0-9\s]+$/i,
  alphaNumericWithoutSpace: /^[A-Za-z0-9_]+$/i,
  url: /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/,
  boolean: /^(true|false)$/i,
  number: /^-?\d+(\.\d+)?$/,
  string: /^[\s\S]*$/,
  date: /^(\d{4}[-/]\d{2}[-/]\d{2}|\d{2}[-/]\d{2}[-/]\d{4})$/,
  file: /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9]+)+$/,
  image: /^[a-zA-Z0-9_-]+(\.(jpg|jpeg|png|gif))$/i,
}
export const USER_VALIDATION_SCHEMA = yup
  .object({
    userName: yup
      .string()
      .required('User Name is required')
      .matches(/^(?!\s*$)[a-zA-Z0-9\s]+$/, VALIDATIONS.ONLY_ALPHANUMERIC),
    status: yup.string().required('Status is required'),
    email: yup.string().required('Email is required'),
    cyberArkId: yup.string().required('CyberarkId is required'),
    roleName: yup.string().required('Role name is required'),
  })
  .required()

// Define the validation schema
export const PARAMETER_VALIDATION_SCHEMA = yup
  .object({
    parameterName: yup
      .string()
      .required('Parameter Name is required')
      .matches(
        /^(?!\s)(?!.*\s{2,})[A-Za-z\s]*$/,
        'Input cannot contain Numbers, special characters & leading spaces or consecutive spaces',
      ),
    fieldName: yup
      .string()
      .required('Field name is required')
      .matches(
        /^(?!\s)(?!.*\s{2,})[\S\s]/,
        'Input cannot contain leading spaces or consecutive spaces',
      ),
    fieldType: yup.string().required('Field Type is required'),
    // master: yup.string().when(['dataType', 'fieldType'], {
    //   is: (dataType: string, fieldType: string) => (dataType === 'String' && fieldType === 'TEXT'),
    //   then: (schema) => schema.required('Master is required for non-string/text data types'),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
    dataType: yup.string().required('Data Type is required'),
    multiSelect: yup.string().required('Multi Select is required'),
    defaultValue: yup
      .string()
      .nullable()
      .transform((value, originalValue) => {
        if (!originalValue) return null
        return originalValue
      })
      .matches(/^\S.*$/, 'Default Value cannot be empty or only spaces'),
    status: yup.string().required('Status is required'),
    esField: yup
      .string()
      .nullable()
      .transform((value, originalValue) => {
        if (!originalValue) return null
        return originalValue
      })
      .matches(/^\S.*$/, 'Es Field cannot be empty or only spaces'),
    mandatory: yup.string().required('Mandatory is required'),
    placeholder: yup
      .string()
      .required('Placeholder is required')
      .matches(
        /^(?!\s)(?!.*\s{2,})[\S\s]/,
        'Input cannot contain leading spaces or consecutive spaces',
      ),
    purpose: yup
      .string()
      .required('Purpose is required')
      .matches(
        /^(?!\s)(?!.*\s{2,})[\S\s]/,
        'Input cannot contain leading spaces or consecutive spaces',
      ),
  })
  .required()
export const SEGMENT_MASTER_VALIDATION_SCHEMA = yup
  .object({
    key: yup
      .string()
      .matches(RegexPatterns.alphaNumericWithoutSpace, VALIDATIONS.WITHOUT_SPACE_ALPHANUMERIC),
    value: yup.string().matches(RegexPatterns.alphaNumeric),
    name: yup.string().matches(RegexPatterns.alphaNumeric),
  })
  .required()
export const SEGMENT_VALIDATION_SCHEMA = yup
  .object({
    segmentName: yup.string().required('Segment Name is required'),
    description: yup
      .string()
      .required('Description is required')
      .matches(/^(?!\s*$)[a-zA-Z0-9\s]+$/, 'Please enter alphanumeric characters only.'),
    abSegments: yup.array(),
    publishingSegments: yup.array(),
    regions: yup.array().of(
      yup.object({
        id: yup.number(),
        countries: yup.array(),
        state: yup.array(),
        cluster: yup.array(),
        packFamily: yup.array(),
        packName: yup.array(),
      }),
    ),

    platforms: yup.array().of(
      yup.object({
        id: yup.number(),
        platformList: yup.array(),
        minVersion: yup.array(),
        maxVersion: yup.array(),
        selectVersion: yup.array(),
      }),
    ),
  })
  .required()

export const WIDGET_PRESENTATION_METADATA_VALIDATION_SCHEMA = yup.object({
  title: yup
    .string()
    .required('Title is required')
    .matches(/^[A-Za-z0-9\s]+$/, VALIDATIONS.ONLY_ALPHANUMERIC),
  subLayoutType: yup.string().required('Sub Layout Type is required'),
  showWidgetTitle: yup.string().required('Show Widget Title is required'),
  overrideWidgetTitle: yup
    .string()
    .nullable()
    .transform((value, originalValue) => {
      // If value is undefined or null, return null
      if (originalValue === undefined || originalValue === null || originalValue === '') {
        return null
      }
      // Trim the value and validate it
      return originalValue
    })
    .matches(/^[A-Za-z0-9\s]+$/, VALIDATIONS.ONLY_ALPHANUMERIC),
})

export const WIDGET_BEHAVIOR_VALIDATION_SCHEMA = yup.object({
  segment: yup.string().required('Segment is required'),
  showWidgetTitle: yup.string().required('Show Widget Title is required'),
  overrideWidgetTitle: yup
    .string()
    .nullable()
    .transform((value, originalValue) => {
      // If value is undefined or null, return null
      if (originalValue === undefined || originalValue === null || originalValue === '') {
        return null
      }
      // Trim the value and validate it
      return originalValue
    })
    .matches(/^[A-Za-z0-9\s]+$/, VALIDATIONS.ONLY_ALPHANUMERIC),
  countryRating: yup.string().nullable(),
})

export const FEATURE_CHECKLIST_VALIDATION_SCHEMA = yup.object({
  featuringTitle: yup.string().required('This field is required'),
  featuringStartDateAndTime: yup.string().required('This field is required'),
  featuringEndDateAndTime: yup.string().required('This field is required'),
  reccuringSchedule: yup.array().of(
    yup.object().shape({
      value: yup.object().shape({
        day: yup.string().required('Day is required'),
        startDateTime: yup.date().required('This field is required'),
        endDateTime: yup.date().required('This field is required'),
      }),
    }),
  ),
})
