import { useParams } from 'react-router-dom'
import { ParameterEditor } from '../../../Components/ManageParameter/index'
const CreateUpdateParameter: React.FC = () => {
  const { id, ParameterPageType = 'create' } = useParams<{
    id: string
    ParameterPageType: 'create' | 'edit' | 'view'
  }>()
  // const getParameters = useCallback(() => {
  //   dispatch(getParametersService() as any)
  // }, [dispatch, id, ParameterPageType])

  // useEffect(() => {
  //   getParameters()
  // }, [])

  return (
    <ParameterEditor
      parameterid={id}
      parameterPageType={ParameterPageType}
      //    roles={roles as createRoleReqData[]}
    />
  )
}

export default CreateUpdateParameter
