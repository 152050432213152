import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import CONSTANTS from '../../../Constants'
import { HOME } from '../../../Constants/ApiDefinations'
import { combineErrorMessage, getDateInFormat, getNavigationLink } from '../../../Helpers/Util'
import { TypeObject } from '../../../Models'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import CircleArrowIcon from '../../Icons/CircleArrowIcon'
import RecentActivityHeaderIcon from '../../Icons/RecentActivityHeaderIcon'

/**
 * RecentActivity Component
 *
 * Renders Recent Activity Asset Component.
 *
 * @returns {React.JSX.Element | null} JSX Element representing the RecentActivity component.
 */

interface RecentActivityProps {
  _id?: string
  state: string
  contentId: number
  title: string
  userName: string
  stateDisplayName: string
  createdAt: string
  contentMongoId: string
  moduleName: string
}

const RecentActivity: React.FC = () => {
  const [activities, setActivities] = useState([])
  const [loader, setLoader] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    getRecentActivities()
  }, [])

  const getRecentActivities = async () => {
    setLoader(true)
    const payload = HOME.RECENT_ACTIVITY
    const result: TypeObject = await mainApiService(payload)

    if (result?.responseCode === 200) {
      setActivities(result?.data)
    }

    if (result?.error?.responseCode === 404) {
      setLoader(false)
      return
    }

    if (result?.error) {
      dispatch(showAlert([true, combineErrorMessage(result), 'info']))
      setLoader(false)

      return
    }
    setLoader(false)
  }

  const getStatus = (state: string) => {
    const statusObj: TypeObject = { ...CONSTANTS.STATE_DISPLAY }
    return statusObj[state.toUpperCase()]
  }

  return (
    <div className='recent-activity-wrapper'>
      {loader ? (
        <div className='loader page'>
          <CircularProgress variant='indeterminate' />
        </div>
      ) : (
        <>
          <h3>
            <RecentActivityHeaderIcon />
            Recent Activity
          </h3>
          <div className='recent-activity-list'>
            {!!activities?.length &&
              activities.map((item: RecentActivityProps) => {
                return (
                  <Link
                    to={getNavigationLink(item)}
                    state={{ fromPage: location.pathname }}
                    style={{ textDecoration: 'none' }}
                    className='table-link'
                    key={item._id}
                  >
                    <div className='activity-row' data-testid='activity-row'>
                      <div className='icon-left'>
                        <CircleArrowIcon />
                      </div>
                      <div className='publised-id pl-1' data-testid='activity-id'>
                        {getStatus(item.state)}{' '}
                        <span className='paragraph-bold-6'>ID: {item.contentId} </span>
                      </div>
                      <div
                        className='publised-text pl-1 text-truncate'
                        data-testid='activity-text'
                        title={item.title?.length > 45 ? item.title : ''}
                      >
                        {item.title}
                      </div>
                      <div className='publised-author pl-1' data-testid='activity-author'>
                        By: {item.userName}{' '}
                        <span>
                          {getDateInFormat(item.createdAt, CONSTANTS.DATE_N_TIME_FORMAT_SECONDS)}
                        </span>
                      </div>
                    </div>
                  </Link>
                )
              })}

            {!loader && activities?.length === 0 && (
              <div className='d-flex align-items-center justify-content-center mt-5 mb-5 heading-3'>
                No Records Found
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default RecentActivity
