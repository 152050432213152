import { Button } from '@mui/material'
import { FC } from 'react'
import { MUIButtonModel } from '../../../Models'

const MUIButton: FC<MUIButtonModel> = ({
  id,
  type,
  label,
  variant,
  color,
  disabled,
  size = 'medium',
  fullWidth = false,
  className = '',
  handleClickCB,
  width = 200,
  height = 'fit-content',
  fontWeight = 500,
  fontSize = '16px',
  startIcon = <></>,
  endIcon = <></>,
  sx = {},
  testID,
}) => {
  return (
    <Button
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      color={color}
      variant={variant}
      fullWidth={fullWidth}
      type={type}
      size={size}
      data-testid={testID ? testID : id ? id : label}
      sx={{ ...sx, minWidth: width, height: height, fontWeight: fontWeight, fontSize: fontSize }}
      className={className}
      onClick={handleClickCB}
    >
      <span data-testid='button-test-spam' style={{ textTransform: 'none' }}>
        {' '}
        {label}
      </span>
    </Button>
  )
}

export default MUIButton
