import { useEffect, useState, MouseEvent } from 'react'
import { BackButton, Breadcrums } from '../../Shared'
import HeaderComponent from '../../Atoms/HeaderComponent'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../Constants'
import styles from '../../ManageWidget/ManageWidget.module.scss'
import { useParams, useNavigate } from 'react-router-dom'
import {
  combineErrorMessage,
  getDateInFormat,
  getHeaderData,
  getPageName,
  handleResponse,
  toCamelCase,
} from '../../../Helpers/Util'
import { useAppDispatch } from '../../../Store/Hooks'
import { getWidgetDetailById } from '../../../Store/Slice/manage-widget.slice'
import { getContainerDetailById } from '../../../Store/Slice/manage-container.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import { IKeyValue } from '../../../Models/CommonListHeadingModal'
import ListingComponent from '../ListingComponent'
import { Tablecolumn } from '../../../Models'
import mainApiService from '../../../Services'
import AssetStateLabel from '../../Labels/AssetStateLabel'
import { showAlert } from '../../../Store/Slice/alert.slice'
import {
  ASSOCIATED_LIST,
  CONTAINER_MANAGEMENT_CRUD,
  SITE_NAVIGATION_CRUD,
  WIDGET_MANAGEMENT_CRUD,
} from '../../../Constants/ApiDefinations'
import { getMasterByKey } from '../../../Store/Slice/system-configuration.slice'

interface ListingData {
  redirectViewAPiRouteMidPoint: string
  entityType: string
  displayName: string
}

const AssociatedItems = () => {
  const { module, id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [detailData, setDetailData] = useState<any>({})
  const [selectedTabs, setSelectedTabs] = useState({ key: '', value: '' })
  const [tabs, setTabs] = useState<IKeyValue[]>([])
  const [columnData, setColumnData] = useState<Tablecolumn[]>([])
  const [listData, setlistData] = useState<any[]>([])
  const [dataCount, setdataCount] = useState(0)
  const [stateList, setStateList] = useState<{ key: string; value: string }[]>([])
  const [layout, setLayout] = useState<{ key: string; value: string }[]>([])
  const [listingProperty, setListingProperty] = useState<ListingData>({
    redirectViewAPiRouteMidPoint: '',
    entityType: '',
    displayName: '',
  })
  const [headerLabel, setHeaderLabel] = useState('')
  const [clearColumnFilter, setClearColumnFilter] = useState<boolean>(false)

  useEffect(() => {
    getDetailDataAccToModule()
  }, [])

  useEffect(() => {
    if (tabs.length > 0) {
      setSelectedTabs(tabs[0])
    }
  }, [tabs])

  useEffect(() => {
    const selectedTabsKey = selectedTabs.key
    if (selectedTabsKey) {
      let listDetail: any
      getLayoutAndState()
      setlistData([])
      switch (selectedTabsKey) {
        case 'container':
          setColumnData(CONSTANTS.ASSOCIATED_CONTAINER_LISTING_COLUMNS)
          listDetail = {
            redirectViewAPiRouteMidPoint: PAGES_ROUTES.ManageContainer,
            entityType: 'container',
            displayName: 'container',
          }
          break

        case 'widget':
          setColumnData(CONSTANTS.ASSOCIATED_WIDGET_LISTING_COLUMNS)
          listDetail = {
            redirectViewAPiRouteMidPoint: PAGES_ROUTES.ManageWidget,
            entityType: 'widget',
            displayName: 'widget',
          }
          break

        case 'siteNavigation':
          setColumnData(CONSTANTS.SITE_NAVIGATION_LISTING_COLUMNS)
          listDetail = {
            redirectViewAPiRouteMidPoint: PAGES_ROUTES.SiteNavigation,
            entityType: 'siteNavigation',
            displayName: 'site navigation',
          }
          break

        default:
          break
      }
      setListingProperty(listDetail)
      setClearColumnFilter(true)
    }
  }, [selectedTabs])

  const getDetailDataAccToModule = async () => {
    dispatch(setLoader(true))
    if (module && id) {
      let listDetail: any
      let result: any
      switch (module) {
        case 'widget':
          result = await dispatch(getWidgetDetailById(id) as any)
          dispatch(setLoader(false))
          setTabs(CONSTANTS.ASSOCIATED_TABS)
          setHeaderLabel('Widget')
          listDetail = {
            redirectViewAPiRouteMidPoint: PAGES_ROUTES.ManageContainer,
            entityType: 'container',
            displayName: 'container',
          }
          break
        case 'container':
          result = await dispatch(getContainerDetailById(id) as any)
          dispatch(setLoader(false))
          setTabs(CONSTANTS.ASSOCIATED_TABS.slice(1, CONSTANTS.ASSOCIATED_TABS.length))
          setHeaderLabel('Container')
          listDetail = {
            redirectViewAPiRouteMidPoint: PAGES_ROUTES.ManageWidget,
            entityType: 'widget',
            displayName: 'widget',
          }
          break

        default:
          break
      }
      setDetailData(result?.payload?.data)
      setListingProperty(listDetail)
    }
  }

  const getLayoutAndState = async () => {
    const result = await dispatch(getMasterByKey('keyName=STATES') as any)
    setStateList(result?.payload?.data)
    let requestedData: any
    let initialResult: any
    switch (selectedTabs.key) {
      case 'widget': {
        requestedData = WIDGET_MANAGEMENT_CRUD.LAYOUT.LIST('excludeSiteNavigationLayouts=true')
        initialResult = await mainApiService(requestedData)
        break
      }
      case 'container': {
        requestedData = CONTAINER_MANAGEMENT_CRUD.LAYOUT.LIST('')
        initialResult = await mainApiService(requestedData)
        break
      }
      case 'siteNavigation': {
        requestedData = SITE_NAVIGATION_CRUD.LAYOUTS_LISTING('Menu Items')
        const assetLayoutsData = await mainApiService(requestedData)
        if (assetLayoutsData?.data?.length > 0) {
          initialResult = {
            data: {
              Layouts: assetLayoutsData.data.map((type: any) => ({
                key: type.key,
                value: type.value,
              })),
            },
          }
        }
        break
      }
      default: {
        initialResult = { data: { Layouts: [] } }
        break
      }
    }
    setLayout(initialResult?.data?.Layouts)
  }

  const handleOnNavigation = (pageData: any) => {
    navigate(`/${pageData.pageLink}`)
  }

  const handleTabSelection = (e: MouseEvent<HTMLDivElement>, tab: IKeyValue) => {
    e.preventDefault()
    setSelectedTabs(tab)
  }

  const displayTabs = () => {
    if (tabs?.length > 0) {
      return tabs.map((tab) => {
        const isSelectedTab = tab.key === selectedTabs?.key
        return (
          <div
            className='font-semibold'
            key={toCamelCase(tab.key)}
            aria-hidden='true'
            onClick={(e) => handleTabSelection(e, tab)}
            style={{
              padding: '0.75rem 1.5rem',
              color: isSelectedTab ? '#402080' : '#3E3F40',
              background: isSelectedTab ? '#ECE9F2' : 'transparent',
              borderRadius: isSelectedTab ? '0.25rem 0.25rem 0 0' : 0,
              borderBottom: isSelectedTab ? '2px solid #402080' : 0,
              cursor: 'pointer',
            }}
          >
            {tab.value}
          </div>
        )
      })
    }
  }

  const getWidgetListFunction = async (options: any) => {
    const moduleDataName = `${module?.toLowerCase()}Id`
    const payload: any = {
      filters: options.filters,
      sortType: options.sortType ? options.sortType : -1,
      page: options.page ? options.page : 1,
      limit: options.limit ? options.limit : 10,
      associationInfo: {
        module: module,
        contentId: detailData?.[moduleDataName]?.toString(),
        tab: selectedTabs.key,
      },
    }
    if (options.sortBy) {
      payload.sortBy = options.sortBy
    }
    if (options.filterValue) {
      payload.searchText = options.filterValue
    }

    dispatch(setLoader(true))
    const requestedData = ASSOCIATED_LIST.LISTING(payload)
    const result: any = await mainApiService(requestedData)
    const { responseCode } = handleResponse(result)
    if (result?.responseCode === 200) {
      const tempData: any[] = []
      switch (selectedTabs.key) {
        case 'widget':
          result?.data?.records?.forEach((ele: any) => {
            tempData.push({
              _id: ele?._id,
              widgetId: ele?.widgetId,
              widgetTitle: ele?.widgetTitle,
              type: ele?.type,
              layout: ele?.layout,
              engine:
                ele?.engine === 'NA'
                  ? ele?.engine
                  : ele?.engine.charAt(0).toUpperCase() + ele?.engine.slice(1).toLowerCase(),
              segment: ele?.segment,
              createdAt: getDateInFormat(ele?.createdAt ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
              updatedAt: getDateInFormat(ele?.updatedAt ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
              lastFeaturedDate: getDateInFormat(
                ele.lastFeaturedDate ?? '',
                CONSTANTS.DATE_FORMAT_TIME_SECONDS,
              ),
              state: <AssetStateLabel state={ele.stateDisplayName} />,
              versionCount: ele?.versionCount,
            })
          })
          break

        case 'container':
          result?.data?.records?.forEach((ele: any) => {
            tempData.push({
              _id: ele?._id,
              containerId: ele?.containerId,
              containerTitle: ele?.containerTitle,
              type: ele?.type,
              layout: ele?.layout,
              segment: ele?.segment,
              language: ele?.language,
              createdAt: getDateInFormat(ele?.createdAt ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
              updatedAt: getDateInFormat(ele?.updatedAt ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
              lastFeaturedDate: getDateInFormat(
                ele.lastFeaturedDate ?? '',
                CONSTANTS.DATE_FORMAT_TIME_SECONDS,
              ),
              state: <AssetStateLabel state={ele.stateDisplayName} />,
              versionCount: ele?.versionCount,
              isChildPageRequired: ele?.isChildPageRequired,
              isVersionRequired: ele?.isVersionRequired,
              isCloneRequired: ele?.isCloneRequired,
            })
          })
          break

        case 'siteNavigation':
          result?.data?.records?.forEach((ele: any) => {
            tempData.push({
              _id: ele?._id,
              siteNavigationId: ele?.siteNavigationId,
              siteNavigationTitle: ele?.siteNavigationTitle,
              layout: ele?.layout,
              segment: ele?.segment,
              createdAt: getDateInFormat(ele?.createdAt ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
              updatedAt: getDateInFormat(ele?.updatedAt ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
              lastFeaturedDate: getDateInFormat(
                ele.lastFeaturedDate ?? '',
                CONSTANTS.DATE_FORMAT_TIME_SECONDS,
              ),
              state: <AssetStateLabel state={ele.stateDisplayName} />,
              versionCount: ele?.versionCount,
            })
          })

          break

        default:
          break
      }

      setdataCount(result?.data?.count)

      setlistData(tempData)
      dispatch(setLoader(false))
    } else if (responseCode === 404) {
      setdataCount(0)
      setlistData([])
      dispatch(setLoader(false))
    } else {
      dispatch(showAlert([true, combineErrorMessage(result), 'error']))
      dispatch(setLoader(false))
    }
  }

  return (
    <div style={{ padding: '0 1.875rem', height: '100vh' }}>
      <div className={styles['breadcrums']} style={{ marginBottom: '0.75rem' }}>
        <Breadcrums breadcrums={BREADCRUMS.ASSOCIATED_ITEMS(module, getPageName(module)) as any} />
      </div>
      <div className={styles['back-btn']}>
        <BackButton
          label={'Associated Item'}
          className='col73'
          handleOnClick={() => handleOnNavigation(getPageName(module))}
        />
      </div>
      <div className={styles['background']}>
        <div className='w-100' style={{ marginBottom: '1.5rem' }}>
          <HeaderComponent
            headerLabel={headerLabel}
            status={detailData?.stateDisplayName}
            type={detailData?.type}
            layout={detailData?.layout}
            title={detailData?.title}
            headerData={getHeaderData(detailData, headerLabel)}
          />
        </div>
      </div>
      <div className='w-100'>
        <div className='d-flex' style={{ gap: '1rem', backgroundColor: 'white' }}>
          {displayTabs()}
        </div>

        <ListingComponent
          columns={columnData}
          tableRows={listData}
          dataCount={dataCount}
          searchPlaceholder='Search by Title, Id'
          showCreateButton={false}
          noDataPlaceHolder={`No ${listingProperty.displayName} is associated`}
          showAdvanceFilter={true}
          clearFilterValues={clearColumnFilter}
          filterValuesCleared={(value: boolean) => setClearColumnFilter(!value)}
          searchFunction={(search, sorttype, coltype, filters, pagenumber, pagecount) => {
            if (Object.keys(detailData).length > 0 && selectedTabs.key) {
              getWidgetListFunction({
                sortBy: coltype,
                filterValue: search,
                sortType: sorttype,
                page: pagenumber,
                limit: pagecount,
                filters: filters,
              })
            }
          }}
          searchBarSize='large'
          isAssocaitedList={true}
          handleRowClick={(event) => {
            navigate(
              `/${listingProperty.redirectViewAPiRouteMidPoint}/${PAGES_ROUTES.PageTypeView}/${event._id}`,
            )
          }}
          paginationOption={[
            { value: 10, label: 10 },
            { value: 20, label: 20 },
            { value: 50, label: 50 },
          ]}
          showFilterOptions={true}
          showVersionColumn={true}
          searchLabel='Search'
          showSearchIcon={false}
          entityType={listingProperty?.entityType}
          filterFields={[
            {
              id: 'layout',
              lable: 'Filter By Layout',
              searchable: true,
              clearall: true,
              options: layout,
            },
            {
              id: 'state',
              lable: 'Filter By State',
              searchable: false,
              clearall: true,
              options: stateList,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default AssociatedItems
