import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IManageSiteNavigationSlice } from '../../Models/ManageSiteNavigation'
import { setLoader } from './loader.slice'
import mainApiService from '../../Services'
import { SITE_NAVIGATION_CRUD, WIDGET_MANAGEMENT_CRUD } from '../../Constants/ApiDefinations'

export interface SiteNavigationCRUDAPIDefinitionModal {
  url: string
  method: string
  data: any
}

const initialState: IManageSiteNavigationSlice = {
  definitionDetails: {},
  details: {},
}

const siteNavigationSlice = createSlice({
  name: 'siteNavigation',
  initialState,
  reducers: {
    setDefinitionDetails: (state, action: PayloadAction<any>) => {
      state.definitionDetails = { ...action.payload }
    },
    setDetails: (state, action: PayloadAction<any>) => {
      state.details = { ...action.payload }
    },
    resetData: () => initialState,
  },
})

export const { setDefinitionDetails, setDetails, resetData } = siteNavigationSlice.actions
export default siteNavigationSlice

export const siteNavigationSelector = (state: any) => state.siteNavigation

export const detailSelector = createSelector(
  [siteNavigationSelector],
  (parameters) => parameters.details,
)
export const definitionDetailSelector = createSelector(
  [siteNavigationSelector],
  (parameters) => parameters.definitionDetails,
)

export const getDefinitionDetailById = createAsyncThunk(
  'siteNavigation/getDefinitionDetailById',
  async (data: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: SiteNavigationCRUDAPIDefinitionModal =
      WIDGET_MANAGEMENT_CRUD.GET_WIDGET_DEFINITION_BY_ID(data)
    const result: any = await mainApiService(requestedData)
    dispatch(setDefinitionDetails(result?.data))
    dispatch(setLoader(false))
    return result
  },
)
export const getDetailById = createAsyncThunk(
  'siteNavigation/getDetailById',
  async (data: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: SiteNavigationCRUDAPIDefinitionModal =
      SITE_NAVIGATION_CRUD.GET_DETAIL_BY_ID(data)
    const result: any = await mainApiService(requestedData)
    dispatch(setDetails(result?.data))
    dispatch(setLoader(false))
    return result
  },
)
