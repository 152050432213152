const FeaturedIcon = () => {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='4' y='4' width='48' height='48' rx='24' fill='#ECE9F2' />
      <rect x='4' y='4' width='48' height='48' rx='24' stroke='#E2DEEC' strokeWidth='8' />
      <g clipPath='url(#clip0_10771_51174)'>
        <path
          d='M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z'
          stroke='#402080'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_10771_51174'>
          <rect width='24' height='24' fill='white' transform='translate(16 16)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FeaturedIcon
