import { MoreVertOutlined, Search, SortByAlpha } from '@mui/icons-material'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import LastPageIcon from '@mui/icons-material/LastPage'
import React, { ChangeEvent, memo, ReactNode, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getDateInFormat, getDynamicRowValue, replaceUnwanted } from '../../../Helpers/Util'
import useDebounce from '../../../Hooks/useDebounce'
import { UserMuiTableModal } from '../../../Models/ComponentModel'
import { BackButton, MUIButton, MUIGrid, MUIPagination, SimpleDropdown } from '../../Shared'
import { DescendingIcon } from '../../Shared/MUI-Table/MUI-Table.svgicons'
import { NoAssetIcon } from '../../../Components/Icons'
import CloneModal from '../../Shared/CloneModal'
import ListingFilter from '../../Atoms/ListingFilter'
import CONSTANTS, { PAGES_ROUTES } from '../../../Constants'
import AssetStateLabel from '../../Labels/AssetStateLabel'
import mainApiService from '../../../Services'
import {
  CONTAINER_MANAGEMENT_CRUD,
  SITE_NAVIGATION_CRUD,
  WIDGET_MANAGEMENT_CRUD,
} from '../../../Constants/ApiDefinations'
import VersionIcon from '../../../Assets/Images/svgs/version-icon'
import { TooltipText } from '../../Shared/TooltipText/TooltipText'
import { EditIcon } from '../../Icons'
import SortIcon from '@mui/icons-material/Sort'
import {
  filterConditions,
  defaultFilterConditionValue,
} from '../../../Constants/Constant/ListingPageConstant/ContentListingConstants'
import MUIDateTimePicker from '../../MUIDateTimePicker'
import { CircularProgress } from '@mui/material'

/**
 * ListingComponent is a reusable component that displays a list of items.
 *
 * @param {Object} props - The props object for the component.
 * @param {Object} props.cloneProps - Properties for cloning functionality.
 * @param {boolean} props.cloneProps.isClonable - Whether the items can be cloned (true/false).
 * @param {string} props.cloneProps.cloneSegmentIdNode - ID node for clone API payload (e.g., 'parameterId').
 * @param {string} props.cloneProps.apiPayloadIdNode - Exact ID node name required in payload as per Swagger (e.g., 'parameterId', 'segmentId').
 * @param {string} props.cloneProps.apiMidpoint - API endpoint midpoint for cloning as per Swagger(e.g., 'parameter').
 * @param {string} props.cloneProps.redirectViewApiMidpoint - API endpoint midpoint for viewing cloned items (e.g., 'parameter').
 * @param {string} props.cloneProps.titleNode - Node name for title in API response as per Swagger  API response(e.g., 'parameterName', 'widgetTitle').
 */
const ListingComponent: React.FC<UserMuiTableModal> = ({
  showTable = true,
  columns,
  tableRows = [],
  tableTitle,
  headerBtnLable = 'Add new',
  headerBtnVarient = 'contained',
  createButtonLable = 'Create',
  createButtonClick,
  searchFunction,
  handleRowClick,
  paginationOption = [],
  dataCount = 0,
  searchPlaceholder = 'Search',
  showCreateButton = true,
  showSearchField = true,
  isRowClickable = true,
  isCellClickable = true,
  showBackButton = false,
  emptyStateText,
  cloneToDisable = '',
  cloneProps = {
    isClonable: false,
    cloneSegmemt: '',
    apiMidPoint: '',
    redirectViewAPiRouteMidPoint: '',
    titleNodeToDisplay: '',
    title: '',
    openInNewTab: false,
  },
  handelBackButton,
  showHeaderInfo = [],
  searchLabel = '',
  showSearchIcon = true,
  searchFullWidth = false,
  showFilterOptions = false,
  filterFields = [],
  parentName = '',
  lastsearchFieldValue = '',
  setlastsearchFieldValue,
  searchClassName = '',
  searchBarSize = '',
  paramValue,
  showVersionColumn = false,
  entityType = 'widget',
  resetPageNumber = false,
  initialCheckedState,
  noDataPlaceHolder = 'No Data Found',
  isFilterSingleSelect = false,
  showAdvanceFilter = false,
  isAssocaitedList = false,
  clearFilterValues,
  filterValuesCleared,
  isLoading = false,
}) => {
  const navigate = useNavigate()
  const [pageData, setPageData] = useState({
    pageCount: 10,
    pageNumber: 1,
  })
  const [colData, setColData] = useState({
    selectedCol: '',
    selectedOptionType: 0,
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchInput, setSearchInput] = useState<string>('')
  const [filterValue] = useState<{
    filterBY: string
    filterText: string
  }>({ filterBY: '', filterText: '' })
  const [mounted, setMounted] = useState(false)
  const [selectedId, setSelectedId] = useState<number | string>('')
  const [selectedCol, setSelectedCol] = useState({
    selectedCol: '',
    selectedOptionType: 0,
  })
  const [selectedVersion, setSelectedVersion] = useState<any[]>([])
  const [selectedVersionId, setSelectedVersionId] = useState([])
  const [showVersions, setShowVersions] = useState(false)
  const [condition, setCondition] = useState(defaultFilterConditionValue)
  const [openFilter, setOpenFilter] = useState(false)
  const [filterInput, setFilterInput] = useState<string>('')
  const [selectedDateTime, setSelectedDateTime] = useState<any>()
  const [filters, setFilters] = useState<any[]>([])

  const openFilterInSort = () => {
    setOpenFilter(true)
  }
  const closeFilterInSort = () => {
    setOpenFilter(false)
    onMenuClose(true)
  }
  const handleConditionChange = (event: any) => {
    setCondition(event?.target.value)
  }
  const handleFilterInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterInput(event?.target.value)
  }
  const handleFilterDateChange = (date: any) => {
    setSelectedDateTime(date)
  }
  const getFilterOptions = (columnDef: any, metaAttribute: any) => {
    const filterValueType = metaAttribute.filter((col: { id: any }) => col.id === columnDef)[0]
      .dataType

    switch (filterValueType) {
      case 'Number':
        return filterConditions[CONSTANTS.FIELDTYPES.NUMBER]

      case 'String':
        return filterConditions[CONSTANTS.FIELDTYPES.TEXT]

      case 'Date':
        return filterConditions[CONSTANTS.FIELDTYPES.DATETIME]

      default:
        return []
    }
  }

  useEffect(() => {
    if (resetPageNumber) {
      setPageData((pre) => ({ ...pre, pageNumber: 1 }))
    }
  }, [resetPageNumber])

  useEffect(() => {
    if (clearFilterValues) {
      setFilterInput('')
      setFilters([])
      setSearchInput('')
      if (filterValuesCleared) filterValuesCleared(true)
    }
  }, [clearFilterValues])

  const handleSortIconClick = (event: React.MouseEvent<HTMLSpanElement>, colName: string) => {
    getFilterOptions(colName, columns)
    setPreviousSelectedFilterValue(colName, columns)
    event.preventDefault()
    if (selectedCol.selectedCol !== colName) {
      setColData((pre) => ({
        ...pre,
        selectedCol: colName as string,
        selectedOptionType: 0,
      }))
      setAnchorEl(event.currentTarget as any)
    } else {
      setColData(() => ({
        ...selectedCol,
        selectedCol: colName as string,
      }))
      setAnchorEl(event.currentTarget as any)
    }
  }

  useEffect(() => {
    if (tableRows && tableRows?.length === 0) {
      pageData.pageNumber > 1
        ? setPageData((prev) => ({ ...prev, pageNumber: prev.pageNumber - 1 }))
        : setPageData((prev) => ({ ...prev, pageNumber: 1 }))
    }
  }, [tableRows])

  const handleApply = (col: any) => {
    setOpenFilter(false)
    onMenuClose(true)
    const obj = {
      filterBy: colData.selectedCol,
      filterCondition: condition,
      filterValue: col.dataType === 'Date' ? new Date(selectedDateTime).toISOString() : filterInput,
    }
    handleFilterSubmit(obj, colData.selectedCol)
  }

  const setPreviousSelectedFilterValue = (col: any, cols: any) => {
    const previousFilters = filters.filter((filter) => filter.filterBy === col)
    const colDatatype = cols.filter((filter: any) => filter.id === col)[0]
    if (previousFilters.length) {
      setCondition(previousFilters[0].filterCondition)
      if (colDatatype.dataType === 'Date') {
        setSelectedDateTime(new Date(previousFilters[0].filterValue))
      } else {
        setFilterInput(previousFilters[0].filterValue)
      }
    }
  }

  const onMenuClose = (clearCol = false) => {
    setAnchorEl(null)
    if (clearCol) {
      setColData({
        selectedCol: '',
        selectedOptionType: 0,
      })
    }
    setCondition(defaultFilterConditionValue)
    setFilterInput('')
    setSelectedDateTime('')
  }
  const tablePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    event.preventDefault()
    setPageData((pre) => ({ ...pre, pageNumber: page }))
  }

  const handlePerPageView = (e: any) => {
    e.preventDefault()
    setPageData((pre) => ({ ...pre, pageNumber: 1, pageCount: e.target.value }))
  }

  const searchValue = useDebounce(searchInput, 1000)
  const [refreshPage, setRefreshPage] = useState(false)
  const handleRefresh = (value: boolean) => {
    setRefreshPage(value)
  }

  const handleFilterSubmit = (filter: any, id: string) => {
    if (Object.keys(filter).length === 0) {
      if (filters.length > 0) {
        const updatedFilters = filters.filter((filter) => !(filter.filterBy === id))
        setFilters(updatedFilters)
      } else {
        setFilters([])
      }
    } else {
      if (filters.length === 0) {
        setFilters([filter])
      } else {
        let replaced = false
        const updatedFilters = filters.map((item) => {
          if (item.filterBy === id) {
            replaced = true
            return filter
          }
          return item
        })

        if (!replaced) {
          setFilters((prev) => [...prev, filter])
        } else {
          setFilters(updatedFilters)
        }
      }
    }
  }

  const clearFilter = (id: string) => {
    const updatedFilters = filters.filter((val) => val.filterBy !== id)
    setAnchorEl(null)
    setFilters(updatedFilters)
    setCondition(defaultFilterConditionValue)
    setFilterInput('')
    setSelectedDateTime('')
  }

  const checkIfFilterApplied = (id: string) => {
    return filters.filter((val) => val.filterBy === id).length ? true : false
  }

  const handleVersionIconClick = async (row: any) => {
    if (selectedVersionId === row._id) {
      setShowVersions(!showVersions)
    } else {
      let payload, requestedData
      switch (entityType) {
        case 'widget':
          payload = { widgetId: row.widgetId, _id: row._id }
          requestedData = WIDGET_MANAGEMENT_CRUD.VERSION_LIST(payload)
          break
        case 'container':
          payload = { containerId: row.containerId, _id: row._id }
          requestedData = CONTAINER_MANAGEMENT_CRUD.VERSION_LIST(payload)
          break
        case 'siteNavigation':
          payload = { siteNavigationId: row.siteNavigationId, _id: row._id }
          requestedData = SITE_NAVIGATION_CRUD.VERSION_LIST(payload)
          break
        case 'advanceSearch':
          if (row.contentType === 'container') {
            payload = { containerId: row.contentId, _id: row._id }
            requestedData = CONTAINER_MANAGEMENT_CRUD.VERSION_LIST(payload)
          } else if (row.contentType === 'widget') {
            payload = { widgetId: row.contentId, _id: row._id }
            requestedData = WIDGET_MANAGEMENT_CRUD.VERSION_LIST(payload)
          } else if (row.contentType === 'site-navigation') {
            payload = { siteNavigationId: row.contentId, _id: row._id }
            requestedData = SITE_NAVIGATION_CRUD.VERSION_LIST(payload)
          }
          break

        default:
          return
      }
      const result: any = await mainApiService(requestedData)
      const updatedData = result?.data.map((item: any) => ({
        ...item,
        contentId: item.widgetId || item.containerId || item.siteNavigationId,
        title: item.widgetTitle || item.containerTitle || item.siteNavigationTitle,
        contentType: item.widgetId ? 'widget' : item.containerId ? 'container' : 'siteNavigation',
      }))

      const fieldTransformations: Record<string, (value: string) => string | JSX.Element> = {
        engine: (value: string) =>
          value === 'NA' ? value : value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
        createdAt: (value: string) =>
          getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
        updatedAt: (value: string) =>
          getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
        lastFeaturedDate: (value: string) =>
          getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME_SECONDS),
        state: (value: string) => <AssetStateLabel state={value} />,
      }
      // Function to apply transformations dynamically
      const transformData = (data: any) => {
        return Object.keys(data).reduce((acc: any, key: string) => {
          const value = key === 'state' ? data['stateDisplayName'] : data[key]
          // Apply transformation if a transformation function exists for the key
          acc[key] = fieldTransformations[key] ? fieldTransformations[key](value) : value
          return acc
        }, {})
      }
      const tempData: any[] = updatedData?.map((ele: any) => transformData(ele))
      setSelectedVersionId(row._id)
      setSelectedVersion(tempData)
    }
  }

  const displayValue = (value: any) => {
    if (typeof value === 'string' || Array.isArray(value)) {
      return value ? <TooltipText text={value.toString()} length={40} /> : 'NA'
    }
    return value || 'NA'
  }

  const showChildVersions = (rowURL: any, ind: any) => {
    return selectedVersion.map((versionData: any, index: number) => {
      return (
        <TableRow
          component={rowURL ? Link : TableRow}
          to={rowURL ? rowURL : ''}
          key={ind}
          onMouseEnter={() => {
            setSelectedId(versionData._id)
          }}
          onMouseLeave={() => {
            setSelectedId('')
          }}
          onClick={(e: any) => {
            e.stopPropagation()
            if (handleRowClick) handleRowClick(versionData)
          }}
          data-testid='table-row-click'
          sx={{
            borderTop: index === 0 ? '12px solid #f5f4f4' : '',
            borderBottom: index === selectedVersion.length - 1 ? '12px solid #f5f4f4' : '',
          }}
        >
          <TableCell
            style={{ cursor: 'pointer' }}
            className={`dashLine-cell ${selectedId === versionData._id ? 'bg-col4' : ''}`}
            align='left'
          >
            <span
              className={`${cloneProps?.isClonable ? '' : 'dashLine-clone'} ${
                index === 0 ? 'listing-dashLine-first' : 'listing-dashLine'
              }`}
            ></span>
          </TableCell>
          {columns?.map((column, index) => {
            const value = versionData[column.id]
            return (
              <TableCell
                key={column.id}
                className={selectedId === versionData._id ? 'bg-col4' : ''}
              >
                <div
                  className={`${
                    isCellClickable ? 'cursor-pointer' : ''
                  } paragraph-regular-6 interFont`}
                  data-testid='tablerow'
                >
                  {displayValue(value)}
                </div>
              </TableCell>
            )
          })}
        </TableRow>
      )
    })
  }

  useEffect(() => {
    if (searchFunction && anchorEl === null) {
      if (showFilterOptions) {
        searchFunction(
          searchValue,
          selectedCol.selectedOptionType,
          selectedCol.selectedCol,
          filters,
          1,
          pageData.pageCount,
        )
      } else {
        searchFunction(
          searchValue,
          selectedCol.selectedOptionType,
          selectedCol.selectedCol === 'stateDisplayName' ? 'state' : selectedCol.selectedCol,
          filterValue,
          1,
          pageData.pageCount,
        )
      }
      setPageData((pre) => ({ ...pre, pageNumber: 1 }))
    }
  }, [searchValue, selectedCol, filterValue, pageData.pageCount, filters, paramValue, refreshPage])

  useEffect(() => {
    if (searchFunction && mounted) {
      if (showFilterOptions) {
        searchFunction(
          searchValue,
          selectedCol.selectedOptionType,
          selectedCol.selectedCol,
          filters,
          pageData.pageNumber,
          pageData.pageCount,
        )
      } else {
        searchFunction(
          searchValue,
          selectedCol.selectedOptionType,
          selectedCol.selectedCol,
          filterValue,
          pageData.pageNumber,
          pageData.pageCount,
        )
      }
    }
    setMounted(true)
  }, [pageData.pageNumber, refreshPage])
  useEffect(() => {
    if (lastsearchFieldValue) {
      setSearchInput(lastsearchFieldValue)
    }
  }, [])

  useEffect(() => {
    if (selectedVersion.length > 0) {
      setShowVersions(true)
    }
  }, [selectedVersion])

  return (
    <MUIGrid
      className='interFont listing-container'
      style={{
        width: '100%',
        backgroundColor: '#f5f4f4',
        padding: !isAssocaitedList ? '20px 24px' : '0 0 20px 0',
      }}
    >
      <Grid className='interFont c-table'>
        {!isAssocaitedList ? (
          <>
            <MUIGrid
              container
              className={`d-flex justify-content-between align-items-center ${
                searchFullWidth ? 'search-full-width' : ''
              }`}
            >
              <MUIGrid item md={6} lg={6}>
                {showBackButton && (
                  <BackButton
                    label={`${tableTitle}`}
                    className='col73'
                    handleOnClick={handelBackButton}
                  />
                )}
                {!showBackButton && tableTitle && <p className='listing__title'>{tableTitle}</p>}
              </MUIGrid>

              <MUIGrid
                item
                md={searchFullWidth ? 12 : 6}
                lg={searchFullWidth ? 12 : 6}
                className={searchClassName ? searchClassName : ''}
              >
                <MUIGrid container className='d-flex justify-content-end align-items-center'>
                  <MUIGrid direction='row' className='search-container' gap={3} display='flex'>
                    {showSearchField && (
                      <TextField
                        className={`c-input listing-search ${
                          searchBarSize === 'large' ? 'large' : ''
                        }`}
                        id='outlined-search'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value?.length === 0) {
                            if (setlastsearchFieldValue) {
                              setlastsearchFieldValue('')
                            }
                          }
                          setSearchInput(e.target.value)
                        }}
                        placeholder={searchPlaceholder}
                        label={searchLabel}
                        inputProps={{
                          'data-testid': replaceUnwanted(searchPlaceholder),
                        }}
                        autoComplete='off'
                        InputProps={{
                          id: 'searchId',
                          startAdornment: (
                            <>
                              {showSearchIcon && (
                                <InputAdornment position='start'>
                                  <Search style={{ color: '#B1B4B6' }} />
                                </InputAdornment>
                              )}
                            </>
                          ),
                        }}
                        type='search'
                      />
                    )}
                    {showCreateButton && showTable && (
                      <MUIButton
                        id={replaceUnwanted(
                          headerBtnLable === 'Add new' ? createButtonLable : headerBtnLable,
                        )}
                        className='create__btn'
                        variant={headerBtnVarient}
                        label={headerBtnLable === 'Add new' ? createButtonLable : headerBtnLable}
                        type='button'
                        data-testid='create-btn-click'
                        handleClickCB={createButtonClick}
                      />
                    )}
                  </MUIGrid>
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
            {showHeaderInfo && (
              <MUIGrid container className='d-flex justify-content-between align-items-center'>
                {showHeaderInfo}
              </MUIGrid>
            )}

            {showFilterOptions && (
              <Box sx={{ display: 'flex', justifyContent: 'end', gap: 3, paddingTop: '20px' }}>
                {filterFields.map((item, index) => (
                  <ListingFilter
                    key={item.id}
                    searchable={item.searchable}
                    clearAll={item.clearall}
                    lable={item.lable}
                    id={item.id}
                    options={item.options ?? []}
                    searchFunction={handleFilterSubmit}
                    isFilterSingleSelect={isFilterSingleSelect}
                  />
                ))}
              </Box>
            )}
          </>
        ) : (
          <div
            style={{
              height: '11rem',
              backgroundColor: '#F7F8F8',
            }}
          >
            {showFilterOptions && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  gap: 3,
                  padding: '10px 24px 0 24px',
                }}
              >
                {filterFields.map((item, index) => (
                  <ListingFilter
                    key={item.id}
                    searchable={item.searchable}
                    clearAll={item.clearall}
                    lable={item.lable}
                    id={item.id}
                    options={item.options ?? []}
                    searchFunction={handleFilterSubmit}
                    isFilterSingleSelect={isFilterSingleSelect}
                  />
                ))}
              </Box>
            )}
            <div className='associatedListSearch'>
              {showSearchField && (
                <TextField
                  style={{
                    backgroundColor: 'white',
                  }}
                  fullWidth
                  id='outlined-search'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput(e.target.value)
                  }}
                  placeholder={searchPlaceholder}
                  value={searchInput}
                  label={searchLabel}
                  inputProps={{
                    'data-testid': replaceUnwanted(searchPlaceholder),
                  }}
                  autoComplete='off'
                  InputProps={{
                    id: 'searchId',
                    startAdornment: (
                      <>
                        {showSearchIcon && (
                          <InputAdornment position='start'>
                            <Search style={{ color: '#B1B4B6' }} />
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                  type='search'
                />
              )}
            </div>
          </div>
        )}

        {showTable ? (
          <TableContainer
            data-testid='listing__table'
            className={`border-6 shadow-table ${!isAssocaitedList ? 'mt-2' : 'mt-1'}`}
            style={{ backgroundColor: '#ffff' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {showVersionColumn || cloneProps?.isClonable ? <TableCell>{''}</TableCell> : null}
                  {columns?.map((column: any, ind: any) => {
                    return (
                      <TableCell key={column.id} align='center'>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'space-between',
                            fontSize: '12px',
                            fontWeight: 'bold',
                          }}
                        >
                          <div className='interFont'>{column.label}</div>
                          {column.sort && (
                            <div
                              data-testid='openfilter'
                              id='openFilter'
                              className='m-4 d-flex align-items-center cursor-pointer'
                              onClick={(event) => {
                                handleSortIconClick(event, column.id)
                              }}
                            >
                              {checkIfFilterApplied(column.id) && column.showFilter ? (
                                <SortIcon
                                  style={{
                                    fontSize: '18px',
                                    marginLeft: '7px',
                                  }}
                                />
                              ) : (
                                <MoreVertOutlined
                                  style={{
                                    fontSize: '18px',
                                    fill:
                                      column.id === selectedCol.selectedCol ? '#402080' : '#b1b4b6',
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {column.id === colData.selectedCol && (
                            <Menu
                              className='border-12 c-sort'
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => onMenuClose(true)}
                              data-testid='sort-dialog'
                            >
                              <div className='d-flex justify-content-between align-items-center'>
                                <Typography>Sort By</Typography>
                                {showAdvanceFilter && column.showFilter && (
                                  <span
                                    className={
                                      checkIfFilterApplied(colData.selectedCol)
                                        ? 'clear-all'
                                        : 'clear-all-disabled'
                                    }
                                    data-testid='clear-all-button'
                                    onClick={() => {
                                      clearFilter(colData.selectedCol)
                                    }}
                                  >
                                    Clear Filter
                                  </span>
                                )}
                              </div>
                              <RadioGroup
                                aria-label='options'
                                name='options'
                                value={colData.selectedOptionType}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  setColData({
                                    ...colData,

                                    selectedOptionType: parseInt(event.target.value),
                                  })
                                  setAnchorEl(null)
                                  onMenuClose()
                                  setSelectedCol({
                                    ...colData,
                                    selectedOptionType: parseInt(event.target.value),
                                  })
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    paddingLeft: 0,
                                  }}
                                  className='d-flex justify-content-between align-items-center'
                                  onClick={() => {
                                    onMenuClose()
                                  }}
                                >
                                  <FormControlLabel
                                    labelPlacement='start'
                                    value={1}
                                    label={
                                      <div className='d-flex w-100 align-item-center justify-content-center'>
                                        <div className='d-flex align-items-center'>
                                          <SortByAlpha style={{ fontSize: '18px' }} />
                                        </div>
                                        <div className='ml-8p'>
                                          <span className='font-12p'>Ascending</span>
                                        </div>
                                      </div>
                                    }
                                    control={<Radio className='ml-auto' />}
                                  />
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    paddingLeft: 0,
                                  }}
                                  className='d-flex justify-content-between align-items-center'
                                  onClick={() => {
                                    onMenuClose()
                                  }}
                                >
                                  <FormControlLabel
                                    labelPlacement='start'
                                    value={-1}
                                    label={
                                      <div className='d-flex w-100 align-item-center justify-content-center'>
                                        <div className='d-flex align-items-center'>
                                          <DescendingIcon />
                                        </div>
                                        <div className='ml-8p'>
                                          <span className='font-12p'>Descending</span>
                                        </div>
                                      </div>
                                    }
                                    control={<Radio />}
                                  />
                                </MenuItem>
                              </RadioGroup>
                              {showAdvanceFilter && column.showFilter && (
                                <>
                                  <MUIButton
                                    width={150}
                                    fontSize={14}
                                    color='secondary'
                                    height='40px'
                                    type='button'
                                    data-testid='filter-button'
                                    label={'Filter'}
                                    variant='text'
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                    startIcon={<FilterListIcon sx={{ marginRight: '8px' }} />}
                                    endIcon={<LastPageIcon sx={{ fontSize: '20px !important' }} />}
                                    handleClickCB={openFilterInSort}
                                  />
                                  {openFilter && (
                                    <Menu
                                      className='border-12 c-sort'
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl)}
                                      onClose={closeFilterInSort}
                                      data-testid='filter-value'
                                      sx={{ top: '180px' }}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                      PaperProps={{
                                        style: {
                                          width:
                                            column && column.dataType === 'Date'
                                              ? '310px'
                                              : '245px',
                                          maxWidth: '400px',
                                          overflow: 'auto',
                                        },
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          fontWeight: 'bold',
                                          marginBottom: '18px',
                                        }}
                                      >
                                        Filter
                                      </Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between',
                                          height: '170px',
                                        }}
                                      >
                                        <FormControl fullWidth>
                                          <InputLabel id='demo-simple-select-label'>
                                            Condition
                                          </InputLabel>
                                          <Select
                                            labelId='demo-simple-select-helper-label'
                                            id='demo-simple-select-helper'
                                            data-testid='condition-filter'
                                            value={condition}
                                            label='Condition'
                                            onChange={handleConditionChange}
                                          >
                                            {getFilterOptions(column.id, columns).map(
                                              (item, index) => (
                                                <MenuItem key={item.label} value={item.value}>
                                                  {item.label}
                                                </MenuItem>
                                              ),
                                            )}
                                          </Select>
                                        </FormControl>
                                        {column && column.dataType === 'Date' ? (
                                          <div data-testid='date-input'>
                                            <MUIDateTimePicker
                                              name={'StartDate'}
                                              label={'Value'}
                                              type={'datetime'}
                                              onChange={handleFilterDateChange}
                                              value={selectedDateTime}
                                              views={CONSTANTS.DATE_TIME_FORMAT_VIEWS}
                                              error={{}}
                                            />
                                          </div>
                                        ) : (
                                          <TextField
                                            id='outlined-basic'
                                            label='Value'
                                            variant='outlined'
                                            value={filterInput}
                                            onChange={handleFilterInputChange}
                                            onKeyDown={(e: any) => {
                                              e.stopPropagation()
                                            }}
                                          />
                                        )}
                                        <MUIButton
                                          width={150}
                                          fontSize={14}
                                          height='44px'
                                          type='button'
                                          label={'Apply'}
                                          variant='contained'
                                          handleClickCB={() => handleApply(column)}
                                          disabled={!(filterInput || selectedDateTime)}
                                        />
                                      </Box>
                                    </Menu>
                                  )}
                                </>
                              )}
                            </Menu>
                          )}
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && tableRows?.length > 0
                  ? tableRows?.map((row, ind) => {
                      // for masters URL is already available in CONSTANTS
                      const url: string = row?.url ?? ''
                      const id = getDynamicRowValue(row, 'id')
                      const disableRadioCheckBox = row?.checkbox?.props?.className?.includes(
                        'replace-asset-radio disabled-row',
                      )
                      const rowToDisable =
                        row?.checkbox?.props?.disabled ||
                        row?.checkbox?.props?.children?.[0]?.props?.disabled ||
                        disableRadioCheckBox
                      // for everything else passing from the parent component
                      const rowURL: string = row?.rowURL ?? url ?? ''
                      const isCloneDisabled = row[cloneToDisable] === 'NA'
                      return (
                        <>
                          <TableRow
                            component={rowURL ? Link : TableRow}
                            to={rowURL ? rowURL : ''}
                            key={row.id}
                            className={`table-link ${isRowClickable ? 'cursor - pointer' : ''} ${
                              rowToDisable ? 'disabled-row' : ''
                            } ${initialCheckedState?.[id] ? 'selected-row' : ''}`}
                            onMouseEnter={() => {
                              setSelectedId(ind)
                            }}
                            onMouseLeave={() => {
                              setSelectedId('')
                            }}
                            onClick={(e: any) => {
                              e.stopPropagation()
                              if (handleRowClick) handleRowClick(row)
                            }}
                            data-testid='table-row-click'
                          >
                            {showVersionColumn || cloneProps?.isClonable ? (
                              <TableCell
                                style={{ cursor: 'pointer' }}
                                className={selectedId === ind ? 'bg-col4' : ''}
                                sx={{ paddingRight: '22px !important' }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div
                                  className={`version-clone-icons ${
                                    !showVersionColumn ? 'flex-start' : ''
                                  }`}
                                >
                                  {showVersionColumn && row?.versionCount > 1 && (
                                    <div
                                      className='version-icon'
                                      data-testid='cloneBtn'
                                      onClick={() => {
                                        handleVersionIconClick(row)
                                      }}
                                    >
                                      <VersionIcon />
                                      <span className='version-count'>{row?.versionCount}</span>
                                    </div>
                                  )}

                                  {entityType === 'container' &&
                                  row?.isChildPageRequired?.toLowerCase() === 'yes' ? (
                                    <div
                                      className='listing-edit-icon'
                                      data-testid='cloneBtn'
                                      onClick={() => {
                                        navigate(
                                          `/${PAGES_ROUTES.ManageContainer}/${PAGES_ROUTES.PageTypeView}/${row._id}`,
                                        )
                                      }}
                                    >
                                      <EditIcon />
                                    </div>
                                  ) : entityType === 'container' ? (
                                    row?.isCloneRequired?.toLowerCase() === 'yes' &&
                                    cloneProps?.isClonable ? (
                                      <div className='clone-icons'>
                                        <CloneModal
                                          cloneProps={{
                                            redirectViewAPiRouteMidPoint:
                                              cloneProps.redirectViewAPiRouteMidPoint,
                                            cloneSegmemtIdNodeForApiPayload:
                                              cloneProps.cloneSegmemtIdNodeForApiPayload,
                                            apiMidPoint: cloneProps.apiMidPoint,
                                            titleNodeToDisplay: cloneProps.titleNodeToDisplay,
                                            title: cloneProps.title,
                                            isCloneDisabled: isCloneDisabled,
                                            openInNewTab: cloneProps.openInNewTab,
                                          }}
                                          id={row._id}
                                          onCancel={handleRefresh}
                                        />
                                      </div>
                                    ) : (
                                      <div style={{ width: '28px' }}></div>
                                    )
                                  ) : (
                                    cloneProps?.isClonable && (
                                      <div className='clone-icons'>
                                        <CloneModal
                                          cloneProps={{
                                            redirectViewAPiRouteMidPoint:
                                              cloneProps.redirectViewAPiRouteMidPoint,
                                            cloneSegmemtIdNodeForApiPayload:
                                              cloneProps.cloneSegmemtIdNodeForApiPayload,
                                            apiMidPoint: cloneProps.apiMidPoint,
                                            titleNodeToDisplay: cloneProps.titleNodeToDisplay,
                                            title: cloneProps.title,
                                            isCloneDisabled: isCloneDisabled,
                                            openInNewTab: cloneProps.openInNewTab,
                                          }}
                                          id={row._id}
                                          onCancel={handleRefresh}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </TableCell>
                            ) : null}

                            {columns?.map((column, index) => {
                              const value: string | number | boolean | ReactNode = row[column.id]
                              return (
                                <TableCell
                                  key={column.id}
                                  className={selectedId === ind ? 'bg-col4' : ''}
                                >
                                  <div
                                    className={`${
                                      isCellClickable ? 'cursor-pointer' : ''
                                    } paragraph-regular-6 interFont`}
                                    data-testid='tablerow'
                                  >
                                    {displayValue(value)}
                                  </div>
                                </TableCell>
                              )
                            })}
                          </TableRow>

                          {showVersions &&
                            selectedVersionId === row._id &&
                            showChildVersions(rowURL, ind)}
                        </>
                      )
                    })
                  : null}
              </TableBody>
            </Table>

            {isLoading && (
              <div className='d-flex justify-content-center' style={{ padding: '100px 0' }}>
                <CircularProgress variant='indeterminate' />
              </div>
            )}
            {!tableRows.length ? (
              parentName.length > 0 ? (
                searchValue?.trim()?.length > 0 ? (
                  <div
                    className={'d-flex justify-content-center align-items-center flex-column'}
                    style={{ paddingTop: '7rem', height: '100%', paddingBottom: '5rem' }}
                  >
                    <div>
                      <NoAssetIcon />
                    </div>
                    <Typography
                      className='d-flex justify-content-center'
                      style={{ padding: '0 50px', fontWeight: 600 }}
                      data-testid={`div_${replaceUnwanted(tableTitle)}_no_data_found`}
                    >
                      {`No ${parentName || 'Data'} Found`}
                    </Typography>
                  </div>
                ) : (
                  <div
                    className={'d-flex justify-content-center align-items-center flex-column'}
                    style={{ paddingTop: '7rem', height: '100%', paddingBottom: '5rem' }}
                  >
                    <div>
                      <NoAssetIcon />
                    </div>
                    <Typography
                      className='d-flex justify-content-center'
                      style={{ padding: '0 50px', fontWeight: 'bold' }}
                      data-testid={`div_${replaceUnwanted(tableTitle)}_no_data_found`}
                    >
                      {emptyStateText || 'Please initiate search to add.'}
                    </Typography>
                  </div>
                )
              ) : (
                !isLoading && (
                  <Typography
                    className='d-flex justify-content-center'
                    style={{ padding: '50px 50px' }}
                    data-testid={`div_${replaceUnwanted(tableTitle)}_no_data_found`}
                  >
                    {noDataPlaceHolder}
                  </Typography>
                )
              )
            ) : null}
          </TableContainer>
        ) : (
          <></>
        )}

        {showTable ? (
          <MUIGrid
            container
            className='d-flex align-items-center justify-content-between mt-2 c-pagination'
          >
            {dataCount > 0 && (
              <MUIGrid item direction='row' className='d-flex align-items-center col1'>
                <SimpleDropdown
                  selectId='pageId'
                  options={paginationOption}
                  handleChange={handlePerPageView}
                  value={pageData.pageCount}
                />

                <Typography paddingX={3}>
                  Showing {pageData.pageCount > dataCount ? dataCount : tableRows.length} of{' '}
                  {dataCount} records
                </Typography>
              </MUIGrid>
            )}
            <MUIGrid item>
              {tableRows.length && dataCount > 0 ? (
                <MUIPagination
                  testId='userPagination'
                  onChange={tablePaginationChange}
                  count={Math.ceil(dataCount / Number(pageData.pageCount))}
                  page={pageData.pageNumber}
                />
              ) : (
                <></>
              )}
            </MUIGrid>
          </MUIGrid>
        ) : (
          <></>
        )}
      </Grid>
    </MUIGrid>
  )
}

export default memo(ListingComponent)
