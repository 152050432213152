import { Tooltip } from '@mui/material'

export const TooltipText = ({ text, length = 25 }: { text: string; length: number }) => {
  const truncatedValue = () => {
    return `${text.slice(0, length)}${text.length >= length ? '...' : ''}`
  }

  return text?.length > length ? (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            padding: '0.25rem 0.75rem',
            fontSize: '0.75rem',
            fontWeight: '500',
            lineHeight: '1.5rem',
            width: 'max-content',
            // color: '#402080',
            // background: '#ECE9F2',
            // '& .MuiTooltip-arrow': {
            //   color: '#ECE9F2',
            // },
          },
        },
      }}
      title={
        <span
          onClick={(e) => (e?.stopPropagation ? e.stopPropagation() : '')} // Stop propagation
          onMouseDown={(e) => (e?.stopPropagation ? e.stopPropagation() : '')} // Prevent default
          onMouseUp={(e) => (e?.stopPropagation ? e.stopPropagation() : '')} // Prevent default
        >
          {text}
        </span>
      }
      arrow
    >
      <span data-testid='truncated'>{truncatedValue()}</span>
    </Tooltip>
  ) : (
    <span>{text || ''}</span>
  )
}
