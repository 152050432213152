import { useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import styles from './BackButton.module.scss'
import ConfirmDialog from '../Dialog/ConfirmDialog'

interface BackButtonProps {
  className?: string
  handleOnClick?: any
  label?: string
  isFormUpdated?: boolean
}

const BackButton = ({ className, handleOnClick, label, isFormUpdated }: BackButtonProps) => {
  const [openDialog, setOpenDialog] = useState(false)

  const handleCategorySelection = () => {
    if (isFormUpdated) {
      setOpenDialog(true)
    } else {
      handleOnClick()
    }
  }

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        title='Unsaved Changes'
        description='Your changes have not been saved. Make sure to save your data to prevent any loss of entered information.'
        onClose={() => setOpenDialog(false)}
        onConfirm={handleOnClick}
        btn2Label='Ignore'
        btn1Label='Okay'
        btn2Color='warning'
        icon='DeleteRoleWarIcon'
        testID1='BackOkayBtn'
        testID2='BackIgnoreBtn'
      />
      <div className={`${styles.backBtnWrapper} ${className}`} data-testid='backBtnWrapper'>
        <div
          data-testid='t-click-btn'
          className={`${styles.leftAerrow}`}
          onClick={handleCategorySelection}
        >
          <KeyboardBackspaceIcon />
        </div>
        <p className={`${className} capitalize`}>{label}</p>
      </div>
    </>
  )
}

export default BackButton
