import { useState } from 'react'
import { DeleteOutline, KeyboardArrowDown } from '@mui/icons-material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { CONSTANTS } from '../../../Constants/Constant'
import { MUIGrid } from '../../Shared'
import AssetStateLabel from '../../Labels/AssetStateLabel'
import { getDateInFormat } from '../../../Helpers/Util'
import { IKeyValue } from '../../../Models/CommonListHeadingModal'
import style from '../../Molecule/Molecule.module.scss'
import headerStyles from './HeaderComponent.module.scss'
import { Tooltip } from '@mui/material'
import { TooltipText } from '../../Shared/TooltipText/TooltipText'

interface HeaderComponentModal {
  headerLabel: string | undefined
  status?: string
  type: string
  layout: string
  title: string
  onDeleteIconClick?: () => void | undefined
  headerData: { title: string; value: string | number | IKeyValue[]; type?: string }[]
}

const { DATE_N_TIME_FORMAT_SECONDS, DISABLE_DELETE_BUTTON } = CONSTANTS

export default function HeaderComponent({
  headerLabel,
  status = 'NA',
  type = 'NA',
  layout = 'NA',
  title = 'NA',
  onDeleteIconClick,
  headerData,
}: Readonly<HeaderComponentModal>) {
  const [showDetails, setShowDetails] = useState(false)
  const canDelete =
    !DISABLE_DELETE_BUTTON.includes(status?.toLowerCase()) &&
    !DISABLE_DELETE_BUTTON.includes(layout?.toLowerCase())
  const displayArrayItemValue = (value: string) => {
    if (value?.length <= 25) return value
    return (
      <Tooltip
        title={value}
        placement='bottom'
        arrow
        slotProps={{
          tooltip: {
            sx: {
              padding: '0.25rem 0.75rem',
              fontSize: '0.75rem',
              fontWeight: '500',
              lineHeight: '1.5rem',
              color: '#402080',
              bgcolor: '#ECE9F2',
              maxWidth: '238px',
              width: 'max-content',
              '& .MuiTooltip-arrow': {
                color: '#ECE9F2',
              },
            },
          },
        }}
      >
        <p style={{ margin: 0, cursor: 'pointer' }}>{value?.slice(0, 25)}...</p>
      </Tooltip>
    )
  }
  const renderArrayValue = (value: IKeyValue[]) => {
    if (value.length === 0) return 'NA'

    return (
      <div className='d-flex align-items-center' style={{ gap: '6px' }}>
        <p style={{ background: '#E7E8E8', padding: '0.7px 0.5rem', borderRadius: '20px' }}>
          {displayArrayItemValue(value?.[0]?.value)}
        </p>
        {value.length > 1 && (
          <Tooltip
            title={value.map((item) => item?.value).join(', ')}
            placement='bottom'
            arrow
            slotProps={{
              tooltip: {
                sx: {
                  padding: '0.25rem 0.75rem',
                  fontSize: '0.75rem',
                  fontWeight: '500',
                  lineHeight: '1.5rem',
                  color: '#402080',
                  bgcolor: '#ECE9F2',
                  maxWidth: '238px',
                  width: 'max-content',
                  '& .MuiTooltip-arrow': {
                    color: '#ECE9F2',
                  },
                },
              },
            }}
          >
            <p style={{ margin: 0, cursor: 'pointer' }}>{`+${value.length - 1} others`}</p>
          </Tooltip>
        )}
      </div>
    )
  }

  const getContentValues = (value?: string | number | IKeyValue[], type?: string) => {
    if (!value) return 'NA'

    if (type === 'date' && typeof value === 'string')
      return getDateInFormat(value, DATE_N_TIME_FORMAT_SECONDS)

    if (Array.isArray(value)) return renderArrayValue(value)

    return String(value)
  }

  const dynamicContentMuiGrid = () => {
    if (!headerData || headerData.length === 0) {
      return null // Return null if no header data
    }

    return headerData.map((header) => {
      const { title, value, type } = header

      return (
        <MUIGrid key={title} xs={6} lg={4}>
          <p className={`${headerStyles.headerDataWrapper}`}>
            <span style={{ fontWeight: '400', marginRight: '0.25rem' }} className='col34'>
              {`${title} :`}
            </span>
            <span className={`col36 ${style.header_value}`}>{getContentValues(value, type)}</span>
          </p>
        </MUIGrid>
      )
    })
  }
  return (
    <div className='asset__editor-header'>
      <div className='asset__editor-info'>
        <div style={{ position: 'relative' }}>
          <div
            aria-hidden={true}
            className='cursor-pointer mt-1'
            data-testid='asset_header_accordion'
            onClick={() => {
              setShowDetails(!showDetails)
            }}
          >
            <MUIGrid
              container
              className={`d-flex justify-content-between align-items-center ${
                headerStyles.widgetHeaderRow
              } ${style.asset_header_static} ${showDetails ? headerStyles.AccordionOpen : ''}`}
            >
              <MUIGrid className={headerStyles.widgetHeaderCol}>
                <p className='d-flex align-items-center' style={{ gap: '10px' }}>
                  <span className={`col34 ${headerStyles.widgetHeaderTitle}`}>
                    {`${headerLabel} Type`} :
                  </span>
                  <span className={`col36 capitalize ${style.f_12_w_600}`}>{type}</span>
                  <AssetStateLabel state={status} />
                </p>
              </MUIGrid>
              <MUIGrid
                className={`d-flex align-items-center ${headerStyles.widgetHeaderCol}`}
                style={{ maxWidth: '50%', wordBreak: 'break-all', gap: '10px' }}
              >
                <span className={`col34 ${headerStyles.widgetHeaderTitle}`}>Layout :</span>
                <span className={`col36 ${style.header_value}`}>{layout || 'NA'}</span>
              </MUIGrid>
              <MUIGrid
                className={`d-flex align-items-center ${headerStyles.widgetHeaderCol} ${headerStyles.widgetTitle}`}
              >
                <span className={`col34 ${headerStyles.widgetHeaderTitle}`}>Title :</span>
                <span className={`col36 ${style.w_600}`}>
                  <span className='d-flex align-items-center'>
                    {title ? <TooltipText text={title} length={35} /> : 'NA'}
                  </span>
                </span>
              </MUIGrid>
              <div className={headerStyles.accorionIcon}>
                {showDetails ? (
                  <KeyboardArrowUpIcon className='col33' />
                ) : (
                  <KeyboardArrowDown className='col33' />
                )}
              </div>
            </MUIGrid>
          </div>
          {showDetails && (
            <MUIGrid
              container
              columnSpacing={2}
              rowSpacing={2}
              className={`d-inline-block ${style.asset_header_dynamic_bottom}`}
              style={{ width: '100%', margin: 'auto' }}
            >
              {dynamicContentMuiGrid()}
              {onDeleteIconClick && (
                <div
                  aria-hidden={true}
                  onClick={() => {
                    if (canDelete) onDeleteIconClick()
                  }}
                  className={`col37 d-flex align-items-center ${headerStyles.deleteIcon} ${
                    canDelete ? 'cursor-pointer' : 'disabled'
                  }`}
                  data-testid={canDelete ? 'deleteIcon__test' : 'deleteIcon__test_disabled'}
                >
                  <DeleteOutline className='col37' />
                </div>
              )}
            </MUIGrid>
          )}
        </div>
      </div>
    </div>
  )
}
