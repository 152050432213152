import { subStringWithEllipses } from '../../Helpers/Util'
import { TypeObject } from '../../Models'
import { CONSTANTS } from '../Constant'
import { PAGES_ROUTES, PAGE_NAME } from '../PagesRoutes'

const BREADCRUMS_LABEL = {
  HOME: 'Home',
  ROLE_MANAGEMENT: 'Role Management',
  ROLE: 'Role',
  USER_MANAGEMENT: 'User Management',
  USER: 'User',
  ASSET_TYPE_DEFINITION: 'Asset Definition',
  ASSET: 'Asset',
  CREATE_ASSET: 'Create Asset',
  UPDATE_ASSET: 'Update Asset',
  CREATE_CHILD_ASSET: 'Create Child Asset',
  UPDATE_CHILD_ASSET: 'Update Child Asset',
  VIEW_ASSET: 'View Child Asset',
  SHOW: 'Show',
  ADVANCED_SEARCH: 'Advanced Search',
  MANAGE_SEGMENT: 'Manage Segment',
  SEGMENT: 'Segment',
  PARAMETER_MANAGEMENT: 'Parameter',

  CONTAINER_DEFINITION: 'Container Definition',
  WIDGET_DEFINITION: 'Widget Definition',
  MANAGE_WIDGET: 'Manage Widget',
  WIDGET: 'Widget',
  MANAGE_CONTAINER: 'Manage Container',
  CONTAINER: 'Container',
  SITE_NAVIGATION: 'Site Navigation',
  AUDIT_LOGS: 'Audit Logs',
  GLOBAL_SEARCH: 'Global Search',
  AUDIT_LOGS_VIEW: 'View',
  AUDIT_ITEM_LOGS: 'Item Logs',
  AUDIT_TRAIL: 'Audit Trails',
  MANAGE_TIPS: 'Manage Tips',
  TIPS: 'Tips',
  ASSOCIATED_ITEMS: 'Associated Item',
}

export const BREADCRUMS = {
  CREATE_ROLE: (id: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    { label: BREADCRUMS_LABEL.ROLE_MANAGEMENT, path: `/${PAGES_ROUTES.RolesPage}` },
    {
      label: `${type} ${BREADCRUMS_LABEL.ROLE}`,
      path: `/${PAGES_ROUTES.RolesPage}/${id}`,
      active: true,
    },
  ],
  CREATE_USER: (id: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    { label: BREADCRUMS_LABEL.USER_MANAGEMENT, path: `/${PAGES_ROUTES.ManageUser}` },
    {
      label: `${type} ${BREADCRUMS_LABEL.USER}`,
      path: `/${PAGES_ROUTES.ManageUser}/${id}`,
      active: true,
    },
  ],
  CREATE_PARAMETER: (id: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    {
      label: BREADCRUMS_LABEL.PARAMETER_MANAGEMENT,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ParameterRepository}`,
    },
    {
      label: `${type} ${BREADCRUMS_LABEL.PARAMETER_MANAGEMENT}`,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ParameterRepository}/${id}`,
      active: true,
    },
  ],
  CREATE_ASSET_TYPE: (id: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    {
      label: 'Meta Master',
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.AssetsDefination}`,
    },
    {
      label: BREADCRUMS_LABEL.ASSET_TYPE_DEFINITION,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.AssetsDefination}`,
    },
    {
      label: `${type} ${BREADCRUMS_LABEL.ASSET}`,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.AssetsDefination}/${id}`,
      active: true,
    },
  ],
  VIEW_USER_ROLE: (id: string | number | undefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    { label: 'Role Management', path: '/manage-role' },
    {
      label: 'View Role',
      path: `/manage-role/${id}/${type}`,
    },
    {
      label: 'Associated Users',
      path: `/manage-role/${id}/${type}/users`,
      active: true,
    },
  ],
  CREATE_ATTRIBUTE: (id: string | number | undefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Meta Master',
      path: '/BoltMaster/attribute-repo',
    },
    {
      label: 'Attribute Repository',
      path: '/BoltMaster/attribute-repo',
    },
    {
      label: `${type} Attributes`,
      path: `/BoltMaster/attribute-repo/${id}`,
      active: true,
    },
  ],
  VIEW_GEO_POLICY: (id: string | number | undefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    { label: 'Meta Master', path: '/BoltMaster/geoPolicy' },
    {
      label: 'Geo Policy Management',
      path: `/BoltMaster/geoPolicy/${id}/${type}`,
      active: true,
    },
  ],
  CREATE_ASSET: (data: TypeObject) => {
    let arrayObject: Array<TypeObject> = [
      { label: PAGE_NAME.HomeLabel, path: PAGES_ROUTES.HomePage },
      { label: PAGE_NAME.ManageContentLabel, path: `/${PAGES_ROUTES.ManageContent}/tv_show` },
      {
        label: data?.conCategoryLabel,
        path: `/${PAGES_ROUTES.ManageContent}/${data?.conCategory?.toLowerCase()}`,
      },
    ]
    const assetPath = `/asset/${data?.pageType}/${data?.contentId}/${data?.assetType}/${data?.conCategory}/${data?.assetCategory}/${data?.metaCategory}`
    if (data?.parentDetails) {
      arrayObject = [
        ...arrayObject,
        {
          label: `${data.parentDetails.contentId}-${
            data?.parentDetails?.assetTitle ? data.parentDetails.assetTitle.slice(0, 19) : 'NA'
          }`,
          showTooltip: data?.parentDetails?.assetTitle?.length > 20 ? true : false,
          tooltipText: `${data.parentDetails.contentId}-${subStringWithEllipses(
            data?.parentDetails?.assetTitle ?? 'NA',
          )}`,
          path: `/asset/${data.pageType}/${data.parentDetails._id}/${data.parentDetails.assetType}/${data.parentDetails.contentCategory}/${data.parentDetails.assetCategory}/${data.metaCategory}`,
        },
        {
          label:
            data.pageType === CONSTANTS.PAGE_TYPE.VIEW_ONLY
              ? BREADCRUMS_LABEL.VIEW_ASSET
              : data.state !== 'CREATED'
              ? BREADCRUMS_LABEL.UPDATE_CHILD_ASSET
              : BREADCRUMS_LABEL.CREATE_CHILD_ASSET,
          path: `/asset/${data.pageType}/${data.contentId}/${data.assetType}/${data.conCategory}/${data.assetCategory}/${data.metaCategory}`,
        },
      ]
    } else {
      arrayObject = [
        ...arrayObject,
        {
          label:
            data.pageType === CONSTANTS.PAGE_TYPE.VIEW_ONLY
              ? BREADCRUMS_LABEL.VIEW_ASSET
              : data?.state !== 'CREATED'
              ? BREADCRUMS_LABEL.UPDATE_ASSET
              : BREADCRUMS_LABEL.CREATE_ASSET,
          path: assetPath,
        },
      ]
    }
    if (data?.isAssociated) {
      arrayObject.push({
        label: PAGE_NAME.AssociatedAssests,
        path: `${assetPath}/assoiciated-assets`,
      })
    } else if (data?.isAuditTrails) {
      arrayObject.push({
        label: PAGE_NAME.AuditTrailsLabel,
        path: `${assetPath}/audit-trails/${data?.contentIdNumber}/${data?.blitzId}`,
      })
      if (data?.isAuditTrailsView) {
        arrayObject.push({
          label: 'View',
          path: `${assetPath}/audit-trails/${data?.contentIdNumber}/${data?.blitzId}/view/${data?.auditId}`,
        })
      }
    }
    if (data?.type === 'bulk-image-upload') {
      arrayObject = [
        ...arrayObject,
        {
          label: 'Content Media: Image',
          path: `${assetPath}`,
        },
        {
          label: 'Bulk Upload',
          path: `${assetPath}`,
        },
      ]
    }

    arrayObject[arrayObject.length - 1].active = true

    return arrayObject
  },
  CREATE_AFFILIATE_PARTNERS: (id: string | number | undefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: `${type} Value`,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.AffiliatePartners}/${id}`,
      active: true,
    },
  ],
  CREATE_COUNTRIES: (id: string | number | undefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: `${type} Value`,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.Countries}/${id}`,
      active: true,
    },
  ],
  CREATE_LANGUAGES: (id: string | number | undefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: `${type} Value`,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.Languages}/${id}`,
      active: true,
    },
  ],
  CREATE_DOWNSTREAM_PLATFORMS: (id: string | number | undefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: `${type} Value`,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.DownstreamPlatforms}/${id}`,
      active: true,
    },
  ],
  CREATE_ENTITLEMENT_PACKAGES: (id: string | number | undefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: `${type} Value`,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.EntitlementPackages}/${id}`,
    },
  ],
  CREATE_SYSTEMCONFIGURATION: (
    mastersPageType: string | number | undefined,
    title: string,
    systemConfigurationPageType: string,
  ) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: `${title} ${systemConfigurationPageType}`,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.EntitlementPackages}/${mastersPageType}`,
      active: true,
    },
  ],
  VIEW_USERS_ROLES_LOGS: (id: string | number | undefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Audit Logs',
      path: `/${PAGES_ROUTES.UsersRolesLogs}`,
    },
    { label: 'User Management Logs', path: `/${PAGES_ROUTES.UsersRolesLogs}` },
    {
      label: 'View User Management Logs',
      path: `/${PAGES_ROUTES.UsersRolesLogs}/${id}/${type}`,
      active: true,
    },
  ],
  VIEW_MASTER_LOGS: (id: string | number | undefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Audit Logs',
      path: `/${PAGES_ROUTES.MasterLogs}`,
    },
    { label: 'Master Logs', path: `/${PAGES_ROUTES.MasterLogs}` },
    {
      label: 'View Master Logs',
      path: `/${PAGES_ROUTES.MasterLogs}/${id}/${type}`,
      active: true,
    },
  ],
  VIEW_CONTENT_LOGS: (id: string | number | undefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Audit Logs',
      path: `/${PAGES_ROUTES.ContentLogs}`,
    },
    { label: 'Content Logs', path: `/${PAGES_ROUTES.ContentLogs}` },
    {
      label: 'View Content Logs',
      path: `/${PAGES_ROUTES.ContentLogs}/${id}/${type}`,
      active: true,
    },
  ],
  VIEW_TRANSCODING_LOGS: (id: string | number | undefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Audit Logs',
      path: `/${PAGES_ROUTES.TranscodingLogs}`,
    },
    { label: 'Transcoding Logs', path: `/${PAGES_ROUTES.TranscodingLogs}` },
    {
      label: 'View Transcoding Logs',
      path: `/${PAGES_ROUTES.TranscodingLogs}/${id}/${type}`,
      active: true,
    },
  ],
  AUDIT_TRAILS: (id: string | number | undefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    { label: 'Audit Trails', path: `/${PAGES_ROUTES.AuditTrails}` },
    {
      label: 'View Audit Trails',
      path: `/${PAGES_ROUTES.AuditTrails}/${id}/${type}`,
      active: true,
    },
  ],
  ASSET_STATUS_LISTING: (pageTitle: string | undefined) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/home' },
    { label: 'Asset State', path: '/home' },
    { label: `${pageTitle}`, path: `/${pageTitle}`, active: true },
  ],
  ADVANCED_SEARCH_BREADCRUMS: () => [
    { label: BREADCRUMS_LABEL.HOME, path: PAGES_ROUTES.HomePage },
    { label: BREADCRUMS_LABEL.ADVANCED_SEARCH, path: PAGES_ROUTES.HomePage, active: true },
  ],
  CREATE_SEGMENT: (id: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    { label: BREADCRUMS_LABEL.MANAGE_SEGMENT, path: `/${PAGES_ROUTES.ManageSegment}` },
    {
      label: `${type} ${BREADCRUMS_LABEL.SEGMENT}`,
      path: `/${PAGES_ROUTES.ManageSegment}/${id}`,
      active: true,
    },
  ],
  CREATE_CONTAINER_DEFINITION: (pageType: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    {
      label: 'Bolt Master',
      path: `/${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.ContainerDefinitionList}`,
    },
    {
      label: BREADCRUMS_LABEL.CONTAINER_DEFINITION,
      path: `/${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.ContainerDefinitionList}`,
    },
    {
      label: `${type} ${BREADCRUMS_LABEL.CONTAINER_DEFINITION}`,
      path: `/${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.ContainerDefinitionList}/${pageType}`,
      active: true,
    },
  ],
  CREATE_WIDGET_DEFINITION: (pageType: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    {
      label: 'Bolt Master',
      path: `/${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.WidgetDefinitionList}`,
    },
    {
      label: BREADCRUMS_LABEL.WIDGET_DEFINITION,
      path: `/${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.WidgetDefinitionList}`,
    },
    {
      label: `${type} ${BREADCRUMS_LABEL.WIDGET_DEFINITION}`,
      path: `/${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.WidgetDefinitionList}/${pageType}`,
      active: true,
    },
  ],
  CREATE_MANAGE_WIDGET: (pageType: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    {
      label: BREADCRUMS_LABEL.MANAGE_WIDGET,
      path: `/${PAGES_ROUTES.ManageWidget}`,
    },
    {
      label: `${type} ${BREADCRUMS_LABEL.WIDGET}`,
      path: `/${PAGES_ROUTES.ManageWidget}/${pageType}`,
      active: true,
    },
  ],
  CREATE_MANAGE_CONTAINER: (pageType: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    {
      label: BREADCRUMS_LABEL.MANAGE_CONTAINER,
      path: `/${PAGES_ROUTES.ManageContainer}`,
    },
    {
      label: `${type} ${BREADCRUMS_LABEL.CONTAINER}`,
      path: `/${PAGES_ROUTES.ManageContainer}/${pageType}`,
      active: true,
    },
  ],
  CREATE_SITE_NAVIGATION: (pageType: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    {
      label: BREADCRUMS_LABEL.SITE_NAVIGATION,
      path: `/${PAGES_ROUTES.SiteNavigation}`,
    },
    {
      label: `${type} ${BREADCRUMS_LABEL.SITE_NAVIGATION}`,
      path: `/${PAGES_ROUTES.SiteNavigation}/${pageType}`,
      active: true,
    },
  ],
  AUDIT_TRAILS_BREADCRUMS: () => [
    {
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.HomePage,
    },
    {
      label: BREADCRUMS_LABEL.AUDIT_LOGS,
      path: PAGES_ROUTES.ItemLogs,
    },
    {
      label: BREADCRUMS_LABEL.AUDIT_TRAIL,
      path: PAGES_ROUTES.ItemAuditLogListing,
      active: true,
    },
  ],
  AUDIT_ITEM_LOGS_BREADCRUMS: () => [
    {
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.HomePage,
    },
    {
      label: BREADCRUMS_LABEL.AUDIT_ITEM_LOGS,
      path: PAGES_ROUTES.ItemAuditLog,
      active: true,
    },
  ],
  AUDIT_TRAILS_VIEW_BREADCRUMS: () => [
    {
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.HomePage,
    },
    {
      label: BREADCRUMS_LABEL.AUDIT_ITEM_LOGS,
      path: `/${PAGES_ROUTES.ItemAuditLog}`,
    },
    {
      label: BREADCRUMS_LABEL.AUDIT_LOGS_VIEW,
      path: `/${PAGES_ROUTES.ViewAuditLog}`,
      active: true,
    },
  ],
  GLOBAL_SEARCH_BREADCRUMS: () => [
    {
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.HomePage,
    },
    {
      label: BREADCRUMS_LABEL.GLOBAL_SEARCH,
      path: PAGES_ROUTES.globalSearch,
      active: true,
    },
  ],
  CREATE_TIPS: (id: string | number | undefined, type: string) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    {
      label: BREADCRUMS_LABEL.MANAGE_TIPS,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ManageTips}`,
    },
    {
      label: `${type} ${BREADCRUMS_LABEL.TIPS}`,
      path: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ManageTips}/${id}`,
      active: true,
    },
  ],

  ASSOCIATED_ITEMS: (pageType: string | number | undefined, pageDetail: any) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    {
      label: pageDetail?.pageName,
      path: `/${pageDetail.pageLink}`,
    },
    {
      label: `${BREADCRUMS_LABEL.ASSOCIATED_ITEMS}`,
      // path: `/${PAGES_ROUTES.ManageWidget}/${pageType}`,
      active: true,
    },
  ],
}
