import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ListingComponent from '../../Components/Molecule/ListingComponent'
import { MASTER_MANAGEMENT_CONSTANTS, PAGINATION_OPTION } from '../../Constants/Constant/Masters'
import { emptyFunction, combineErrorMessage } from '../../Helpers/Util'
import { useAppDispatch } from '../../Store/Hooks'
import { clearSystemConfigurationStore } from '../../Store/Slice/system-configuration.slice'
import styles from './MastersList.module.scss'
import { showAlert } from '../../Store/Slice/alert.slice'
import { setLoader } from '../../Store/Slice/loader.slice'
import mainApiService from '../../Services'
import { MASTERS_MANAGEMENT } from '../../Constants/ApiDefinations'
import { PAGES_ROUTES } from '../../Constants'
import { setMaster } from '../../Store/Slice/masters.slice'

const MastersList: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [masterListData, setMasterListData] = useState<any[]>([])
  const [systemConfigListData, setSystemConfigListData] = useState<any[]>([])

  const getUserListFunction = async () => {
    dispatch(setLoader(true))
    const requestedData: any = MASTERS_MANAGEMENT.MASTER.LIST()
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      const masterData = result.data.SegmentationMaster.map((e: any) => {
        return {
          ...e,
          originalUrl: e.url_key,
          url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${e.url_key}`,
        }
      })
      const systemData = result.data.SystemConfiguration.map((e: any) => {
        return {
          ...e,
          originalUrl: e.url_key,
          url: `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${e.url_key}`,
        }
      })
      dispatch(setMaster(masterData.map((e: any) => e.originalUrl)))
      setMasterListData(masterData)
      setSystemConfigListData(systemData)
      dispatch(setLoader(false))
    } else {
      dispatch(showAlert([true, combineErrorMessage(result), 'error']))
      dispatch(setLoader(false))
    }
  }

  useEffect(() => {
    dispatch(clearSystemConfigurationStore({}) as any)
    getUserListFunction()
  }, [])

  const handleMasterClick = (row: any) => {
    const { url = '' } = row
    if (url) {
      navigate(url)
    }
  }

  return (
    <div className={styles.mastersListWrapper}>
      <ListingComponent
        columns={MASTER_MANAGEMENT_CONSTANTS.MASTERS_COLUMNS}
        tableRows={masterListData}
        dataCount={0}
        headerBtnLable={''}
        tableTitle={'Segment Master'}
        searchFunction={emptyFunction}
        showCreateButton={false}
        createButtonClick={emptyFunction}
        paginationOption={PAGINATION_OPTION}
        showSearchField={false}
        navigateToEdit={emptyFunction}
        isRowClickable={true}
        isCellClickable={false}
        handleRowClick={handleMasterClick}
      />

      <ListingComponent
        columns={MASTER_MANAGEMENT_CONSTANTS.MASTERS_COLUMNS}
        tableRows={systemConfigListData}
        dataCount={0}
        headerBtnLable={''}
        tableTitle={'System Configuration'}
        searchFunction={emptyFunction}
        showCreateButton={false}
        createButtonClick={emptyFunction}
        paginationOption={PAGINATION_OPTION}
        showSearchField={false}
        navigateToEdit={emptyFunction}
        isRowClickable={true}
        isCellClickable={false}
        handleRowClick={handleMasterClick}
      />
    </div>
  )
}

export default React.memo(MastersList)
