import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbsPropsModel, BreadcrumsModel } from '../../../Models'
import ConfirmDialog from '../Dialog/ConfirmDialog'

const Breadcrumbs: React.FC<BreadcrumbsPropsModel> = ({ breadcrums, isFormUpdated }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [pendingNavigation, setPendingNavigation] = useState<{
    item: BreadcrumsModel
    index: number
  } | null>(null)
  const navigate = useNavigate()

  const handleOnNavigate = (item: BreadcrumsModel, index: number) => {
    const endPath = breadcrums.length - 1

    if (breadcrums[endPath]?.label !== item?.label && isFormUpdated) {
      // Store the pending navigation info and open the dialog
      setPendingNavigation({ item, index })
      setOpenDialog(true)
    } else {
      // Directly navigate if no unsaved changes
      navigate(index < breadcrums.length - 1 ? item.path : {})
    }
  }

  const handleConfirmNavigation = () => {
    if (pendingNavigation) {
      const { item, index } = pendingNavigation
      navigate(index < breadcrums.length - 1 ? item.path : {})
      setPendingNavigation(null) // Clear the pending navigation state
    }
    setOpenDialog(false) // Close the dialog
  }

  const renderLinkElement = (item: BreadcrumsModel, index: number) => (
    <span
      className={`breadcrums__link ${item.active ? 'active font-bold' : ''}`}
      onClick={() => handleOnNavigate(item, index)}
    >
      <div className='d-flex align-items-center justify-content-center'>
        <div className='capitalize' style={{ cursor: 'pointer' }}>
          {item.label}
        </div>{' '}
        {!item.active && (
          <div className='d-flex justify-content-center align-items-center'>
            <ChevronRightIcon sx={{ fontSize: '16px' }} />
          </div>
        )}
      </div>
    </span>
  )

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        title='Unsaved Changes'
        description='Your changes have not been saved. Make sure to save your data to prevent any loss of entered information.'
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmNavigation} // Handle confirmed navigation here
        btn2Label='Ignore'
        btn1Label='Okay'
        btn2Color='warning'
        icon='DeleteRoleWarIcon'
        testID1='BreadCrumOkayBtn'
        testID2='BreadCrumIgnoreBtn'
      />
      <div className='d-flex align-items-center pr-3'>
        {breadcrums.map((item, index) =>
          item?.showTooltip ? (
            <Tooltip
              data-testid='tooltip-breadcrums'
              title={item.tooltipText}
              key={item.label}
              placement='bottom'
              arrow
            >
              {renderLinkElement(item, index)}
            </Tooltip>
          ) : (
            renderLinkElement(item, index)
          ),
        )}
      </div>
    </>
  )
}

export default Breadcrumbs
