function SparkleIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M7.5 12.75C7.5 12.5925 7.5975 12.4537 7.74 12.4012L9.82875 11.6063C11.7262 10.8863 13.2038 9.32625 13.89 7.3275L14.6437 5.13C14.6962 4.98 14.8387 4.875 15 4.875C15.1613 4.875 15.3038 4.97625 15.3563 5.13L16.11 7.3275C16.7962 9.32625 18.2775 10.8863 20.1712 11.6063L22.26 12.4012C22.4063 12.4575 22.5 12.5963 22.5 12.75C22.5 12.9038 22.4025 13.0463 22.26 13.0988L20.1712 13.8937C18.2737 14.6137 16.7962 16.1737 16.11 18.1725L15.3563 20.37C15.3038 20.52 15.1613 20.625 15 20.625C14.8387 20.625 14.6962 20.5238 14.6437 20.37L13.89 18.1725C13.2038 16.1737 11.7225 14.6137 9.82875 13.8937L7.74 13.0988C7.59375 13.0425 7.5 12.9038 7.5 12.75Z'
        fill='#FFB300'
      />
      <path
        d='M10.73 5.88455L9.53136 6.31818C8.49636 6.69045 7.69045 7.49636 7.31818 8.53136L6.88454 9.73C6.82727 9.89364 6.67182 10 6.5 10C6.32818 10 6.17273 9.89364 6.11546 9.73L5.68182 8.53136C5.30955 7.49636 4.50364 6.69045 3.46864 6.31818L2.27 5.88455C2.10636 5.82727 2 5.67182 2 5.5C2 5.32818 2.10636 5.17273 2.27 5.11545L3.46864 4.68182C4.50364 4.30955 5.30955 3.50364 5.68182 2.46864L6.11546 1.27C6.17273 1.10636 6.32818 1 6.5 1C6.67182 1 6.82727 1.10636 6.88454 1.27L7.31818 2.46864C7.69045 3.50364 8.49636 4.30955 9.53136 4.68182L10.73 5.11545C10.8936 5.17273 11 5.32818 11 5.5C11 5.67182 10.8936 5.82727 10.73 5.88455Z'
        fill='#E6A100'
      />
      <path
        d='M2.264 18.624L3.32 18.244C4.22 17.92 4.92 17.22 5.244 16.32L5.624 15.264C5.68 15.104 5.832 15 6 15C6.168 15 6.32 15.104 6.376 15.264L6.756 16.32C7.08 17.22 7.78 17.92 8.68 18.244L9.736 18.624C9.896 18.68 10 18.832 10 19C10 19.168 9.896 19.32 9.736 19.376L8.68 19.756C7.78 20.08 7.08 20.78 6.756 21.68L6.376 22.736C6.32 22.896 6.168 23 6 23C5.832 23 5.68 22.896 5.624 22.736L5.244 21.68C4.92 20.78 4.22 20.08 3.32 19.756L2.264 19.376C2.104 19.32 2 19.168 2 19C2 18.832 2.104 18.68 2.264 18.624Z'
        fill='#FFB300'
      />
    </svg>
  )
}
export default SparkleIcon
