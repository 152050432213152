import { DeleteOutlineOutlined, ExpandMore } from '@mui/icons-material'
import { unwrapResult } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { PAGES_ROUTES } from '../../../Constants'
import { combineErrorMessage, formatMasterKey, getFormattedWords } from '../../../Helpers/Util'
import { RoleConfirmationModal, defaultParameterData } from '../../../Models'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import RoleConfirmation from '../../Dialogs/RoleConfirmation'
import { ChipButton, MUIBox } from '../../Shared'
import DetailPageRowChip from '../../Atoms/DetailPageRowChip'
import { deleteParameter, changeStatus } from '../../../Store/Slice/parameter.slice'
import { EditIcon } from '../../Icons'

const ParameterInformation: React.FC<{
  view: defaultParameterData
  getParameterData: CallableFunction
}> = ({
  view,
  getParameterData,
}: {
  view: defaultParameterData
  getParameterData: CallableFunction
}) => {
  const [dialogProps, setDialogProps] = React.useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })
  const [associatedUsers, setAssociatedUsers] = useState(0)
  useEffect(() => {
    setAssociatedUsers(0)
  }, [])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleClose = () => {
    setDialogProps({
      heading: '',
      description: '',
      color: 'primary',
      open: false,
    })
  }

  const handleDelete = async () => {
    const result = await dispatch(deleteParameter(view?._id as string) as any)
    const unwrappableRes = unwrapResult(result)

    if (unwrappableRes?.error || !unwrappableRes) {
      if (unwrappableRes?.error?.boltCode === CONSTANTS.BOLT_ERROR_CODE_BLOCK_DELETE_USER) {
        handleClose()

        setDialogProps({
          heading: CONSTANTS.DELETE_PARAMETER_TITLE,
          description: unwrappableRes?.error?.message,
          color: 'warning',
          Icon: 'DeleteRoleIcon',
          open: true,
          iconColor: 'warning',
          cancelButtonLabel: null,
          successButtonLabel: 'Okay',
          handleSuccess: handleClose,
        })
        return
      }

      dispatch(showAlert([true, combineErrorMessage(unwrappableRes), 'error']))
      return
    }

    dispatch(showAlert([true, unwrappableRes.message, 'success']))
    handleClose()
    navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ParameterRepository}`)
  }

  const handleStatusChange = async (type: string) => {
    const result = await dispatch(
      changeStatus({ parameterId: view?._id || '', status: type }) as any,
    )
    const unwrappableRes = unwrapResult(result)

    if (unwrappableRes?.error || !unwrappableRes) {
      handleClose()
      if (unwrappableRes?.error?.responseCode === 422) {
        setDialogProps({
          heading: CONSTANTS.PARAMETER_STATUS_INACTIVE_TITLE,
          description: unwrappableRes?.error?.message,
          color: 'warning',
          Icon: 'DeactivateRoleIcon',
          open: true,
          iconColor: 'warning',
          cancelButtonLabel: null,
          successButtonLabel: 'Okay',
          handleSuccess: handleClose,
        })
        return
      }
      dispatch(showAlert([true, combineErrorMessage(unwrappableRes), 'error']))
      return
    }
    dispatch(
      showAlert([
        true,
        CONSTANTS.MESSAGES.CHANGE_STATUS_MESSAGE_PARAMETER(
          type === 'ACTIVE' ? 'Activated' : 'Deactivated',
        ),
        'success',
      ]),
    )

    // dispatch(setParameterData({ data: { records: [{ ...unwrappableRes.data, status: type }] } }) as any)
    navigate(
      `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ParameterRepository}/${PAGES_ROUTES.ViewTypeParameter}/` +
        view?._id,
    )
    getParameterData(view?._id)
    handleClose()
  }

  const openDialog = (type: string) => {
    if (type === 'delete-warning') {
      setDialogProps({
        heading: CONSTANTS.DELETE_PARAMETER_TITLE,
        description: CONSTANTS.MESSAGES.PARAMETER_BLOCK_ERROR,
        color: 'warning',
        Icon: 'DeleteRoleWarIcon',
        open: true,
        iconColor: 'warning',
        cancelButtonLabel: null,
        successButtonLabel: 'Okay',
        handleSuccess: handleClose,
      })
    }
    if (type === 'delete') {
      setDialogProps({
        heading: CONSTANTS.DELETE_PARAMETER_TITLE,
        description: (
          <div>
            <p>
              Are you sure you want to delete{' '}
              <strong style={{ fontWeight: 'bold', color: '#3E3F40', display: 'inline-block' }}>
                {view?.parameterName}
              </strong>
              ?
            </p>
          </div>
        ),
        color: 'error',
        Icon: 'DeleteRoleIcon',
        open: true,
        iconColor: 'error',
        successButtonLabel: 'Delete',
        cancelButtonLabel: 'Cancel',
        handleSuccess: handleDelete,
      })
    }
    if (type === 'status-active') {
      setDialogProps({
        heading: CONSTANTS.STATUS_ACTIVE_PARAMETER,
        description: (
          <div>
            <p>
              Are you sure you want to activate{' '}
              <strong style={{ fontWeight: 'bold', color: '#3E3F40', display: 'inline-block' }}>
                {view?.parameterName}
              </strong>
              ?
            </p>
          </div>
        ),
        color: 'primary',
        Icon: 'ActivateRoleIcon',
        open: true,
        iconColor: 'success',
        successButtonLabel: 'Activate',
        cancelButtonLabel: 'Cancel',
        handleSuccess: () => handleStatusChange('ACTIVE'),
      })
    }
    if (type === 'status-inactive') {
      if (associatedUsers > 0) {
        setDialogProps({
          heading: CONSTANTS.PARAMETER_STATUS_INACTIVE_TITLE,
          description: CONSTANTS.MESSAGES.INACTIVE_BLOCK_MESSAGE_PARAMETER,
          color: 'warning',
          Icon: 'DeleteRoleWarIcon',
          open: true,
          iconColor: 'warning',
          cancelButtonLabel: null,
          successButtonLabel: 'Okay',
          handleSuccess: handleClose,
        })
      } else {
        setDialogProps({
          heading: CONSTANTS.PARAMETER_STATUS_DEACTIVE_TITLE,
          description: (
            <div>
              <p>
                Are you sure you want to deactivate{' '}
                <strong style={{ fontWeight: 'bold', color: '#3E3F40', display: 'inline-block' }}>
                  {view?.parameterName}
                </strong>
                ?
              </p>
            </div>
          ),
          color: 'error',
          Icon: 'DeactivateRoleIcon',
          open: true,
          iconColor: 'error',
          successButtonLabel: 'Deactivate',
          cancelButtonLabel: 'Cancel',
          handleSuccess: () => handleStatusChange('INACTIVE'),
        })
      }
    }
  }

  const handleDeleteClick = () => {
    openDialog('delete')
  }
  return (
    <>
      <MUIBox>
        <div>
          <div className='heading-6 d-flex text-left rolebox__header-padding'>
            <div className='d-flex align-items-center'>Parameter Information</div>
            <div className='ml-auto d-flex'>
              <div className='mr-1'>
                <ChipButton
                  value={view?.status ?? 'ACTIVE'}
                  type={view?.status === 'ACTIVE' ? 'success' : 'secondary'}
                  dropdown
                  Icon={ExpandMore}
                  label={view?.status === 'ACTIVE' ? 'Active' : 'In Active'}
                  suffix
                  options={CONSTANTS.ROLE_STATUS}
                  action={openDialog}
                />
              </div>

              <div className='mr-1' onClick={handleDeleteClick} data-testid='delete__chip-btn'>
                <ChipButton type='danger' Icon={DeleteOutlineOutlined} label='Delete' />
              </div>

              <div
                onClick={() => {
                  navigate(
                    `/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ParameterRepository}/${PAGES_ROUTES.EditParameterRepository}/${view?._id}`,
                  )
                }}
                data-testid='edit__chip-btn'
              >
                <ChipButton type='primary' Icon={EditIcon} label='Edit' />
              </div>
            </div>
          </div>
          <div className={'hr'}></div>
          <div className=''>
            <DetailPageRowChip
              name={'Parameter Name'}
              value={view?.parameterName?.trim() || 'NA'}
              isDate={false}
            />
            <DetailPageRowChip
              name={'Field Name'}
              value={view?.fieldName?.trim() || 'NA'}
              isDate={false}
            />
            <DetailPageRowChip
              name={'Field Type'}
              value={view?.fieldType?.trim() || 'NA'}
              isDate={false}
            />
            <DetailPageRowChip
              name={'Master'}
              value={formatMasterKey(view?.master) || 'NA'}
              isDate={false}
            />
            <DetailPageRowChip
              name={'Multi Select'}
              value={view?.multiSelect?.trim()}
              isDate={false}
            />
            <DetailPageRowChip
              name={'Default Values'}
              value={view?.defaultValue?.trim() || 'NA'}
              isDate={false}
            />
            <DetailPageRowChip
              name={'Es Field'}
              value={view?.esField?.trim() || 'NA'}
              isDate={false}
            />
            <DetailPageRowChip name={'Mandatory'} value={view?.mandatory} isDate={false} />
            <DetailPageRowChip
              name={'Placeholder'}
              value={view?.placeholder?.trim() || 'NA'}
              isDate={false}
            />
            <DetailPageRowChip
              name={'Purpose'}
              value={view?.purpose?.trim() || 'NA'}
              isDate={false}
            />
            <DetailPageRowChip
              name={'Status'}
              value={view?.status}
              isStatus={true}
              isDate={false}
            />
            <DetailPageRowChip
              name={'Parameters'}
              value={String(view?.parameters?.map((param) => param?.value || '')) || 'NA'}
              isDate={false}
              truncate={false}
            />
          </div>
        </div>
      </MUIBox>
      <RoleConfirmation {...dialogProps} handleClose={handleClose} />
    </>
  )
}

export default React.memo(ParameterInformation)
