import React from 'react'
import CONSTANTS from '../../../../Constants'
import { TIPS_MODULE } from '../../../../Helpers/ManageTips'
import { findKeyByWords, getPageTitle } from '../../../../Helpers/Util'
import { MastersEditorLayoutPropTypes } from '../../../../Models'
import { BackButton, Breadcrumbs, MUITipsCard } from '../../../Shared'
import styles from '../Masters.module.scss'

const MastersEditorLayout = ({
  children,
  mastersPageType,
  onClickBackNavigation,
  breadcrumbs,
}: MastersEditorLayoutPropTypes) => {
  const pageType = getPageTitle(mastersPageType as string)
  return (
    <>
      <div className='w-100'>
        <div className={`${styles.masters__editor} p-16`}>
          <div className='p-8'>
            <Breadcrumbs breadcrums={breadcrumbs} />
          </div>
          <div className='p-8'>
            <BackButton
              label={`${pageType} Value`}
              className='col73'
              handleOnClick={() => {
                onClickBackNavigation()
              }}
            />
          </div>
          <div className={styles.masters__editor_wrapper}>
            <div className={`${styles.masters__editor_content} p-8`}>{children}</div>
            <div className='p-8 ml-1'>
              <MUITipsCard
                tipsArr={CONSTANTS.NO_TIPS}
                module={TIPS_MODULE.MASTERS}
                pageType={pageType}
                page={findKeyByWords(`master ${pageType}`)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(MastersEditorLayout)
