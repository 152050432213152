import { Chip, ClickAwayListener, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'

const Tags = ({ data, handleDelete }: any) => {
  return <Chip label={data} onDelete={() => handleDelete(data)} />
}

export interface MUIInputTagsProps {
  /** name: unique fieldName */
  name: string

  /** type: input type of the field, default is TEXT*/
  type: string
  /**
   * error: Error message for the field.
   */
  error: any
  /**
   * User facing label of the field.
   */
  label: string
  /**
   * callback function to be triggered on onChange event of input field.
   * @returns
   */
  onChange: (tags: string[]) => void
  required: boolean
  placeholder: string
  /**
   * List of tags to be shown, if any
   */
  value: string[]
  disabled?: boolean
}

/**
 * My component renders a text message with an optional click handler.
 */
export const MUIInputTags: React.FC<MUIInputTagsProps> = ({
  name,
  type = 'TEXT',
  error,
  label,
  onChange,
  required,
  placeholder,
  value,
  disabled,
}) => {
  const [tags, setTags] = useState<any>([])
  const [inputValue, setInputValue] = useState<any>('')
  const [focus, setFocus] = useState<any>(false)
  const tagRef: any = useRef<any>()

  const dispatch = useAppDispatch()

  const handleDelete = (value: any) => {
    const newtags = tags.filter((val: any) => val !== value)
    setTags(newtags)
    onChange(newtags)
  }
  const handleOnSubmit = (e: any) => {
    if (inputValue?.trim()) {
      if (e?.code === 'Enter' || e?.code === 'Space') {
        if (tags.includes(inputValue)) {
          dispatch(showAlert([true, 'this ' + label + ' already present', 'error']))
          setInputValue('')
          return
        }
        onChange([...tags, inputValue])
        setInputValue('')
      }
    } else {
      setInputValue('')
    }
  }

  /**
   * Handles the `onPaste` event for an HTMLInputElement.
   *
   * This function parses the pasted text, extracts tags,
   * and updates the state with the combined and unique tags.
   * @param e - The React `ClipboardEvent` object triggered by the `onPaste` event.
   */
  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    // Prevent default behaviour to prevent handleInputChange from triggering.
    e.preventDefault()
    const pastedData: string = e.clipboardData.getData('Text') ?? ''

    // Split pasted text into individual tags, by comma(,).
    const pastedTags: string[] = pastedData
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item)
    onChange([...new Set([...tags, ...pastedTags])])
    setInputValue('')
  }

  const handleInputChange = (e: any) => {
    const input = e.target
    const valueWithoutComma = input.value.replace(/,/g, '') // Remove commas from the input value
    input.value = valueWithoutComma
    setInputValue(input.value?.trim())
  }

  useEffect(() => {
    if (value?.length) setTags(value)
  }, [value])

  return (
    <div className='tag__input '>
      <ClickAwayListener onClickAway={() => setFocus(false)}>
        <Box>
          <TextField
            data-testid='mui-input-tag'
            inputRef={tagRef}
            name={name}
            type={type}
            error={!!error?.[name]}
            onKeyDown={handleOnSubmit}
            fullWidth
            disabled={disabled}
            variant='outlined'
            multiline={true}
            inputProps={{ id: name, maxLength: 100 }}
            onFocus={() => setFocus(true)}
            onPaste={handleOnPaste}
            onBlur={() => {
              handleOnSubmit({ code: 'Enter' })
              setFocus(false)
            }}
            maxRows={1}
            helperText={error?.[name]?.message as string}
            margin='none'
            value={inputValue}
            onChange={handleInputChange}
            placeholder={value?.length ? '' : tags?.length ? '' : (placeholder ?? label)}
            label={
              <span>
                {label}
                {required && <span style={{ color: '#D4351C' }}>*</span>}
              </span>
            }
            InputProps={{
              name: name,
              startAdornment: (
                <Box
                  sx={{
                    margin: '0 0.2rem 0 0',
                    display: 'flex',
                    justifyContentCenter: 'center',
                    alignItemsCenter: 'center',
                    padding: value?.length ? '8px 8px 0 8px' : 0,
                  }}
                  className={'width-fill-available'}
                >
                  {focus || tags?.length <= 2 ? (
                    <div className='d-flex justify-content-start flex-wrap width-fill-available gap-2'>
                      {tags.map((data: any, index: any) => {
                        return <Tags data={data} handleDelete={handleDelete} key={index} />
                      })}
                    </div>
                  ) : (
                    <div className='d-flex justify-content-start flex-wrap width-fill-available gap-2'>
                      {tags.slice(0, 2).map((data: any, index: any) => {
                        return <Tags data={data} handleDelete={handleDelete} key={index} />
                      })}
                      {tags.length > 2 ? (
                        <div className='ml-1 d-flex flex-no-wrap justify-content-center align-items-center'>
                          +{tags.length - 2} others
                        </div>
                      ) : null}
                    </div>
                  )}
                </Box>
              ),
            }}
            InputLabelProps={{
              shrink: !!tags.length || !!inputValue || focus,
            }}
          />
        </Box>
      </ClickAwayListener>
    </div>
  )
}
