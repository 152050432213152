import { FC } from 'react'
import { PieChart } from '@mui/x-charts/PieChart'
import { Box, Stack, Typography } from '@mui/material'

interface LegendItem {
  label: string
  color: string
}

interface MUIPieChartProps {
  series: any[]
  width?: number
  height?: number
  legendData?: LegendItem[]
  testID?: string
  sx?: object
  onClick?: (data: any) => void
}

const MUIPieChart: FC<MUIPieChartProps> = ({
  series = [],
  width = 400,
  height = 400,
  legendData = [],
  testID,
  sx = {},
  onClick,
}) => {
  const handlePieClick = (event: any, item: any) => {
    if (onClick && item) {
      onClick(item)
    }
  }

  return (
    <Box
      className='graph-container'
      sx={{ ...sx, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      data-testid={testID}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: `${width}px`,
          height: `${height}px`,
          position: 'relative',
          marginLeft: '90px',
        }}
      >
        <PieChart
          series={series}
          width={width}
          height={height}
          sx={{
            overflow: 'hidden',
            '& svg': {
              width: `${width}px !important`,
              height: `${height}px !important`,
              margin: '0 auto',
            },
          }}
          slotProps={{
            legend: { hidden: true },
          }}
          onClick={handlePieClick}
        />
      </Box>
      {legendData.length > 0 && (
        <Stack
          direction='row'
          spacing={5}
          sx={{
            justifyContent: 'center',
            flexWrap: 'wrap',
            mt: 2,
            rowGap: 6,
          }}
          className='graph-legend'
        >
          {legendData.map(({ label, color }) => (
            <Stack key={label} direction='row' spacing={2} alignItems='center'>
              <Box
                sx={{
                  width: '25px',
                  height: '25px',
                  backgroundColor: color,
                }}
              />
              <Typography variant='body1' sx={{ fontSize: '1rem' }}>
                {label}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </Box>
  )
}

export default MUIPieChart
